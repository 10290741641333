import Stack from "@mui/material/Stack";
import {
  Box,
  Collapse,
  IconButton,
  LinearProgress,
  Menu,
  MenuItem,
  Tooltip,
  Typography,
  useTheme,
} from "@mui/material";
import { Clear, ExpandLess, MoreVert } from "@mui/icons-material";
import React, { Dispatch, SetStateAction, useMemo, useState } from "react";
import { SelectedImplementerWithProjects } from "../ForecastV2";

interface ProjectsListProps {
  implementers: SelectedImplementerWithProjects[];
  setSelectedImplementers: Dispatch<
    SetStateAction<SelectedImplementerWithProjects[]>
  >;
  projectsLoading: boolean;
}

export default function ProjectsList({
  implementers,
  setSelectedImplementers,
  projectsLoading,
}: ProjectsListProps) {
  const theme = useTheme();

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [open, setOpen] = useState<string | null>(null);

  const [showMultipleRelevantProjects, setShowMultipleRelevantProjects] =
    useState<boolean>(true);
  const [showOneRelevantProject, setShowOneRelevantProject] =
    useState<boolean>(true);
  const [showRelevantRecruitment, setShowRelevantRecruitment] =
    useState<boolean>(true);

  const [expandedProjectsLists, setExpandedProjectsLists] = useState<string[]>(
    [],
  );

  const multipleRelevantProjects = useMemo(
    () =>
      implementers.filter((implementer) =>
        implementer.mode === "separate-included"
          ? implementer.projects.length > 1
          : implementer.mode === "separate-excluded"
          ? implementer.implementerRecordsCount - implementer.projects.length >
            1
          : implementer.implementerRecordsCount > 1,
      ),
    [implementers],
  );

  const oneRelevantProjects = useMemo(
    () =>
      implementers.filter((implementer) =>
        implementer.mode === "separate-included"
          ? implementer.projects.length === 1
          : implementer.mode === "separate-excluded"
          ? implementer.implementerRecordsCount -
              implementer.projects.length ===
            1
          : implementer.implementerRecordsCount === 1,
      ),
    [implementers],
  );

  function handleOpen(event: React.MouseEvent<HTMLButtonElement>) {
    setOpen(event.currentTarget.id);
    setAnchorEl(event.currentTarget);
  }
  function handleClose() {
    setAnchorEl(null);
    setOpen(null);
  }

  function removeProjectFromSelectedImplementer(
    implementerUid: string,
    projectId: number,
  ) {
    setSelectedImplementers((prev) =>
      prev.map((implementer) =>
        implementer.implementerUid === implementerUid
          ? {
              ...implementer,
              mode: "separate-included",
              displayNames: implementer.displayNames.filter(
                (project) => project.projectId !== projectId,
              ),
              projects: implementer.projects.filter((id) => id !== projectId),
            }
          : implementer,
      ),
    );
  }

  function removeImplementerFromSelectedImplementers(implementerUid: string) {
    setSelectedImplementers((prev) =>
      prev.filter(
        (implementer) => implementer.implementerUid !== implementerUid,
      ),
    );
    setExpandedProjectsLists((prev) =>
      prev.filter((id) => id !== implementerUid),
    );
    handleClose();
  }

  return (
    <Box
      sx={{
        width: "40%",
        border: `solid 1px ${theme.palette.divider}`,
        overflow: "scroll",
      }}
    >
      <Box
        sx={{
          p: 1,
          // borderTop: `solid 1px ${theme.palette.divider}`,
          borderBottom: `solid 1px ${theme.palette.divider}`,
          backgroundColor: theme.palette.primary.light,
        }}
      >
        <Typography
          variant={"subtitle2"}
          fontWeight={500}
          color={"textSecondary"}
          fontSize={"13px"}
        >
          SUGGESTED PRIME(S)
          <Box
            sx={{
              height: "4px",
              ml: "-8px",
              mb: "-8px",
              mt: "4px",
              width: "calc(100% + 16px)",
            }}
          >
            {projectsLoading && <LinearProgress />}
          </Box>
        </Typography>
      </Box>
      <Stack
        direction={"row"}
        sx={{
          p: 1,
          // borderTop: `solid 1px ${theme.palette.divider}`,
          borderBottom: `solid 1px ${theme.palette.divider}`,
          backgroundColor: theme.palette.primary.light,
        }}
        justifyContent={"space-between"}
        alignItems={"center"}
      >
        <Typography variant={"subtitle2"} fontWeight={500} color={"inherit"}>
          Multiple Relevant Projects
        </Typography>
        <IconButton
          onClick={() => setShowMultipleRelevantProjects((prev) => !prev)}
        >
          <ExpandLess
            sx={{
              transition: "transform 250ms ease-in-out",
              transform: `rotate(${
                showMultipleRelevantProjects ? "0deg" : "90deg"
              })`,
            }}
          />
        </IconButton>
      </Stack>
      <Collapse in={showMultipleRelevantProjects}>
        <Box>
          {multipleRelevantProjects.length > 0 ? (
            multipleRelevantProjects.map((implementer, _index) => (
              <Box
                sx={{
                  backgroundColor:
                    _index % 2
                      ? theme.palette.background.default
                      : theme.palette.background.paper,
                }}
              >
                <Stack
                  direction="row"
                  sx={{
                    p: 0.5,
                    alignItems: "center",
                    justifyContent: "space-between",
                  }}
                >
                  <Typography
                    variant={"subtitle2"}
                    fontWeight={400}
                    color={"inherit"}
                  >
                    {implementer.implementerName}
                  </Typography>
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                      gap: 0.5,
                    }}
                  >
                    <Typography
                      variant={"subtitle2"}
                      fontWeight={400}
                      color={"inherit"}
                      align={"center"}
                      sx={{
                        width: "32px",
                      }}
                    >
                      {implementer.mode === "separate-included"
                        ? implementer.projects.length
                        : implementer.mode === "all"
                        ? implementer.implementerRecordsCount
                        : implementer.implementerRecordsCount -
                          implementer.projects.length}
                    </Typography>
                    <IconButton
                      id={`menu-button-${implementer.implementerUid}`}
                      sx={{
                        border: `solid 1px ${theme.palette.divider}`,
                        backgroundColor: theme.palette.background.paper,
                      }}
                      onClick={handleOpen}
                    >
                      <MoreVert />
                    </IconButton>
                    <Menu
                      anchorEl={anchorEl}
                      open={
                        open !== null &&
                        open === `menu-button-${implementer.implementerUid}`
                      }
                      onClose={handleClose}
                      MenuListProps={{
                        "aria-labelledby": `menu-button-${implementer.implementerUid}`,
                      }}
                    >
                      <MenuItem
                        onClick={() =>
                          removeImplementerFromSelectedImplementers(
                            implementer.implementerUid,
                          )
                        }
                      >
                        Remove
                      </MenuItem>
                    </Menu>
                    <Tooltip
                      title={
                        "All of the projects of this implementer will be added as primes"
                      }
                      arrow
                      disableInteractive
                      disableFocusListener
                      disableTouchListener
                      disableHoverListener={
                        !(
                          implementer.mode === "all" &&
                          implementer.displayNames.length === 0
                        )
                      }
                    >
                      <span>
                        <IconButton
                          onClick={() => {
                            setExpandedProjectsLists((prev) =>
                              prev.includes(implementer.implementerUid)
                                ? prev.filter(
                                    (implementerUid) =>
                                      implementerUid !==
                                      implementer.implementerUid,
                                  )
                                : [...prev, implementer.implementerUid],
                            );
                          }}
                          disabled={
                            implementer.mode === "all" &&
                            implementer.displayNames.length === 0
                          }
                        >
                          <ExpandLess
                            sx={{
                              transition: "transform 250ms ease-in-out",
                              transform: `rotate(${
                                expandedProjectsLists.includes(
                                  implementer.implementerUid,
                                )
                                  ? "0deg"
                                  : "90deg"
                              })`,
                            }}
                          />
                        </IconButton>
                      </span>
                    </Tooltip>
                  </Box>
                </Stack>
                <Collapse
                  in={expandedProjectsLists.includes(
                    implementer.implementerUid,
                  )}
                >
                  <Box>
                    {implementer.displayNames.map((project) => (
                      <Box
                        sx={{
                          pl: 2,
                          pr: 0.5,
                          py: 1,
                          display: "flex",
                          justifyContent: "space-between",
                          alignItems: "center",
                        }}
                      >
                        <Typography
                          variant={"subtitle2"}
                          fontWeight={400}
                          fontSize={"13px"}
                          color={"inherit"}
                        >
                          {project.projectName}
                        </Typography>
                        <IconButton
                          sx={{
                            border: `solid 1px ${theme.palette.divider}`,
                            backgroundColor: theme.palette.background.paper,
                          }}
                          onClick={() => {
                            removeProjectFromSelectedImplementer(
                              implementer.implementerUid,
                              project.projectId,
                            );
                          }}
                        >
                          <Clear />
                        </IconButton>
                      </Box>
                    ))}
                  </Box>
                </Collapse>
              </Box>
            ))
          ) : (
            <Typography
              variant={"body1"}
              color={"textSecondary"}
              align={"center"}
              fontSize={"13px"}
              sx={{
                width: "100%",
                py: 1,
              }}
            >
              <i>No Primes Suggested</i>
            </Typography>
          )}
        </Box>
      </Collapse>
      <Stack
        direction={"row"}
        sx={{
          p: 1,
          // borderTop: `solid 1px ${theme.palette.divider}`,
          borderBottom: `solid 1px ${theme.palette.divider}`,
          backgroundColor: theme.palette.primary.light,
        }}
        justifyContent={"space-between"}
        alignItems={"center"}
      >
        <Typography variant={"subtitle2"} fontWeight={500} color={"inherit"}>
          One Relevant Project
        </Typography>
        <IconButton onClick={() => setShowOneRelevantProject((prev) => !prev)}>
          <ExpandLess
            sx={{
              transition: "transform 250ms ease-in-out",
              transform: `rotate(${showOneRelevantProject ? "0deg" : "90deg"})`,
            }}
          />
        </IconButton>
      </Stack>
      <Collapse in={showOneRelevantProject}>
        <Box>
          {oneRelevantProjects.length > 0 ? (
            oneRelevantProjects.map((implementer, _index) => (
              <Box
                sx={{
                  backgroundColor:
                    _index % 2
                      ? theme.palette.background.default
                      : theme.palette.background.paper,
                }}
              >
                <Stack
                  direction="row"
                  sx={{
                    p: 0.5,
                    alignItems: "center",
                    justifyContent: "space-between",
                  }}
                >
                  <Typography
                    variant={"subtitle2"}
                    fontWeight={400}
                    color={"inherit"}
                  >
                    {implementer.implementerName}
                  </Typography>
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                      gap: 0.5,
                    }}
                  >
                    <Typography
                      variant={"subtitle2"}
                      fontWeight={400}
                      color={"inherit"}
                      align={"center"}
                      sx={{
                        width: "32px",
                      }}
                    >
                      {implementer.mode === "separate-included"
                        ? implementer.projects.length
                        : implementer.mode === "all"
                        ? implementer.implementerRecordsCount
                        : implementer.implementerRecordsCount -
                          implementer.projects.length}
                    </Typography>
                    <IconButton
                      id={`menu-button-${implementer.implementerUid}`}
                      sx={{
                        border: `solid 1px ${theme.palette.divider}`,
                        backgroundColor: theme.palette.background.paper,
                      }}
                      onClick={handleOpen}
                    >
                      <MoreVert />
                    </IconButton>
                    <Menu
                      anchorEl={anchorEl}
                      open={
                        open !== null &&
                        open === `menu-button-${implementer.implementerUid}`
                      }
                      onClose={handleClose}
                      MenuListProps={{
                        "aria-labelledby": `menu-button-${implementer.implementerUid}`,
                      }}
                    >
                      <MenuItem
                        onClick={() =>
                          removeImplementerFromSelectedImplementers(
                            implementer.implementerUid,
                          )
                        }
                      >
                        Remove
                      </MenuItem>
                    </Menu>
                    <Tooltip
                      title={
                        "All of the projects of this implementer will be added as primes"
                      }
                      arrow
                      disableInteractive
                      disableFocusListener
                      disableTouchListener
                      disableHoverListener={
                        !(
                          implementer.mode === "all" &&
                          implementer.displayNames.length === 0
                        )
                      }
                    >
                      <span>
                        <IconButton
                          onClick={() => {
                            setExpandedProjectsLists((prev) =>
                              prev.includes(implementer.implementerUid)
                                ? prev.filter(
                                    (implementerUid) =>
                                      implementerUid !==
                                      implementer.implementerUid,
                                  )
                                : [...prev, implementer.implementerUid],
                            );
                          }}
                          disabled={
                            implementer.mode === "all" &&
                            implementer.displayNames.length === 0
                          }
                        >
                          <ExpandLess
                            sx={{
                              transition: "transform 250ms ease-in-out",
                              transform: `rotate(${
                                expandedProjectsLists.includes(
                                  implementer.implementerUid,
                                )
                                  ? "0deg"
                                  : "90deg"
                              })`,
                            }}
                          />
                        </IconButton>
                      </span>
                    </Tooltip>
                  </Box>
                </Stack>
                <Collapse
                  in={expandedProjectsLists.includes(
                    implementer.implementerUid,
                  )}
                >
                  <Box>
                    {implementer.displayNames.map((project) => (
                      <Box
                        sx={{
                          pl: 2,
                          pr: 0.5,
                          py: 1,
                          display: "flex",
                          justifyContent: "space-between",
                          alignItems: "center",
                        }}
                      >
                        <Typography
                          variant={"subtitle2"}
                          fontWeight={400}
                          fontSize={"13px"}
                          color={"inherit"}
                        >
                          {project.projectName}
                        </Typography>
                        <IconButton
                          sx={{
                            border: `solid 1px ${theme.palette.divider}`,
                            backgroundColor: theme.palette.background.paper,
                          }}
                          onClick={() => {
                            removeProjectFromSelectedImplementer(
                              implementer.implementerUid,
                              project.projectId,
                            );
                          }}
                        >
                          <Clear />
                        </IconButton>
                      </Box>
                    ))}
                  </Box>
                </Collapse>
              </Box>
            ))
          ) : (
            <Typography
              variant={"body1"}
              color={"textSecondary"}
              align={"center"}
              fontSize={"13px"}
              sx={{
                width: "100%",
                py: 1,
              }}
            >
              <i>No Primes Suggested</i>
            </Typography>
          )}
        </Box>
      </Collapse>
      {/*<Stack*/}
      {/*  direction={"row"}*/}
      {/*  sx={{*/}
      {/*    p: 1,*/}
      {/*    // borderTop: `solid 1px ${theme.palette.divider}`,*/}
      {/*    borderBottom: `solid 1px ${theme.palette.divider}`,*/}
      {/*    backgroundColor: theme.palette.primary.light,*/}
      {/*  }}*/}
      {/*  justifyContent={"space-between"}*/}
      {/*  alignItems={"center"}*/}
      {/*>*/}
      {/*  <Stack*/}
      {/*    direction={{*/}
      {/*      xs: "column",*/}
      {/*      sm: "row",*/}
      {/*    }}*/}
      {/*    sx={{*/}
      {/*      alignItems: "center",*/}
      {/*      rowGap: 0.5,*/}
      {/*      columnGap: 2,*/}
      {/*      flexWrap: "wrap",*/}
      {/*    }}*/}
      {/*  >*/}
      {/*    <Typography variant={"subtitle2"} fontWeight={500} color={"inherit"}>*/}
      {/*      Relevant Recruitment*/}
      {/*    </Typography>*/}
      {/*  </Stack>*/}
      {/*  <IconButton onClick={() => setShowRelevantRecruitment((prev) => !prev)}>*/}
      {/*    <ExpandLess*/}
      {/*      sx={{*/}
      {/*        transition: "transform 250ms ease-in-out",*/}
      {/*        transform: `rotate(${*/}
      {/*          showRelevantRecruitment ? "0deg" : "90deg"*/}
      {/*        })`,*/}
      {/*      }}*/}
      {/*    />*/}
      {/*  </IconButton>*/}
      {/*</Stack>*/}
      {/*<Collapse in={showRelevantRecruitment}>*/}
      {/*  <Box>showRelevantRecruitment</Box>*/}
      {/*</Collapse>*/}
    </Box>
  );
}
