import React, { FC, useCallback, useContext, useEffect, useState } from "react";
import { rowsPerPageOptionsStandard } from "../../utils/constants/gridsOptions";
import { useForm } from "react-hook-form";
import { RootState, useDispatch, useSelector } from "../../store";
import {
  alpha,
  Box,
  Divider,
  Drawer,
  IconButton,
  Tooltip,
  Typography,
  useTheme,
} from "@mui/material";
import { RequestStatus } from "../../utils/Helpers/fetchStatus";
import { useHistory } from "react-router-dom";
import { buildQuery } from "../../utils/Helpers/queryBuilder";
import UserContext, {
  doesUserHaveRole,
  isUserAdminOrSemiAdminWith,
  isUserInRoles,
  ServerOrgRoles,
  UserRoleGroups,
} from "../../services/UserContext";
import CustomTable, {
  ColumnsSettingsData,
  DataColumnConfigProps,
  StickyColumnConfigProps,
} from "../../components/MaterialTable/Table";
import Button from "@mui/material/Button";
import {
  getPipelines,
  getPipelinesForRecord,
  resetAddOrRemoveFromPipelinesStatus,
  resetPipelinesForRecord,
  resetPipelinesState,
  setPipelinesForRecordFor,
  updatePipelines,
} from "../../slices/pipelines";
import axios from "axios";
import {
  createAuthenticatedRequest,
  getFullUrl,
} from "../../configs/axios-export.custom";
import toast from "react-hot-toast";
import ShareModal from "../../components/Widgets/USAIDBidSearchComponents/ShareModal";
import {
  getTableSettings,
  patchTableSettings,
  resetTableSettings,
} from "../../slices/user-settings";
import { extractDataFromSearchParams } from "../../utils/Helpers/extractDataFromSearchParams";
import { format } from "date-fns";
import { generateExcel } from "../../services/exporter";
import { FiltersIcon } from "../../components/Icons/FiltersIcon";
import { handleInvalidDatesToast } from "../../utils/Helpers/invalidDatesErrorToastHandler";
import Input, { DropdownOption } from "../../components/Widgets/Inputs/Input";
import {
  getCategoriesDropdownOptions,
  getCountryDropdownOptions,
  getGrants,
  GrantsFiltersType,
  GrantsPagination,
  resetShareOpportunitiesStatus,
  resetUpdateGrantsRecordState,
  shareOpportunities,
  updateGrantsRecord,
} from "../../slices/grants";
import {
  getGrantsColumnsConfig,
  getGrantsStickyColumnsConfig,
} from "./columns.config";
import { ForecastRecord, PipelineDto } from "../../utils/types/Forecast";
import {
  getEmptyStringWithCurrencyIfNull,
  getExcelData,
  getExportableDataTable,
  getSortableColumnPropertyName,
  getTableHeader,
} from "./grantsUtils";
import GrantsFilters from "./GrantsFilters";
import RowActions from "./RowActions";
import { removeHtmlTags } from "../../services/dataParser";
import GrantsDetailsAndFilesModal from "./GrantsDetailsAndFilesModal";
import { debounce } from "lodash";
import EditIcon from "@mui/icons-material/Edit";
import SimpleModal from "../../components/Modals/SimpleModal";
import { getNewDateNoTimezoneAdjustment } from "../../utils/conversion/date-converters";
import { constants } from "../../utils/constants/general";

interface AdminActionsFormType {
  manualLastUpdatedDate: Date | null;
}

const initialFilters: GrantsFiltersType = {
  filter: "",
  country: [],
  opportunityNumber: "",
  category: [],
  sortField: "manualLastUpdatedDate",
  sortOrder: -1,
  onlyMyList: false,
  includeUsaid: true,
  includeMcc: false,
  includeStateDept: false,
  includeCdc: false,
  fileName: "",
  fileKeyword: "",
  updatedBy: Date.today().addMonths(-6),
  status: "",
  onlyUntrackedInPipeline: false,
  eligibility: "",
};

const initialAdminActions: AdminActionsFormType = {
  manualLastUpdatedDate: null,
};

const initialPagination: GrantsPagination = {
  pageIndex: 0,
  pageSize: rowsPerPageOptionsStandard[0],
};

export const GRANTS_COLUMNS_SETTINGS_KEY = "grantsColumnsSettingsKey";
const FILTERS_FORM_ID = "grants-filters-form";
const ADMIN_EDIT_FORM_ID = "ADMIN_EDIT_FORM_ID";

const Grants: FC = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const MUITheme = useTheme();

  const context = useContext(UserContext);

  const isPlus = isUserInRoles(context, UserRoleGroups.plusOrHigher);
  const isPro = isUserInRoles(context, UserRoleGroups.proOrHigher);
  const canShare = isPlus || isPro;
  const canEdit =
    isUserAdminOrSemiAdminWith(
      context,
      ServerOrgRoles.EditPrimesAndQuarterlyCallInfo,
    ) || doesUserHaveRole(context, ServerOrgRoles.semiAdmin);

  const {
    items,
    fetchStatus,
    total,
    lastUpdatedUtc,
    shareOpportunities: { postFetchStatus: shareOpportunitiesFetchStatus },
    dropdownOptions,
  } = useSelector((state: RootState) => state.grants);
  const {
    rawItems: rawPipelines,
    items: pipelines,
    fetchStatus: pipelinesFetchStatus,
    addOrRemoveFromPipelines: {
      postFetchStatus: addOrRemoveFromPipelinesFetchStatus,
    },
  } = useSelector((state: RootState) => state.pipelines);
  const {
    tableSettings: { columnsOrder, fetchStatus: tableSettingsFetchStatus },
  } = useSelector((state: RootState) => state.userSettings);
  const { fetchStatus: updateGrantsRecordFetchStatus } = useSelector(
    (state: RootState) => state.grants.updateGrantsRecord,
  );
  const {
    fetchStatus: pipelinesForRecordFetchStatus,
    itemsFor: pipelinesForRecordFor,
  } = useSelector((state: RootState) => state.pipelines.pipelinesForRecord);

  const getValuesFromUrl = (): {
    filters: GrantsFiltersType;
    pagination: GrantsPagination;
  } => {
    const filters = extractDataFromSearchParams(
      history.location.search,
      Object.keys(initialFilters),
      [],
      [
        "onlyMyList",
        "includeUsaid",
        "includeMcc",
        "includeStateDept",
        "includeCdc",
        "onlyUntrackedInPipeline",
      ],
      ["sortOrder"],
      ["country", "category"],
    );
    const pagination = extractDataFromSearchParams(
      history.location.search,
      Object.keys(initialPagination),
      [],
      [],
      ["pageIndex", "pageSize"],
    ) as GrantsPagination;
    return {
      filters: { ...initialFilters, ...filters },
      pagination: { ...initialPagination, ...pagination },
    };
  };

  const { control, handleSubmit, reset, setValue, getValues } =
    useForm<GrantsFiltersType>({
      defaultValues: getValuesFromUrl().filters,
    });

  const {
    control: adminActionsControl,
    handleSubmit: adminActionsHandleSubmit,
    reset: adminActionsReset,
    setValue: adminActionsSetValue,
    getValues: adminActionsGetValues,
  } = useForm<AdminActionsFormType>({
    defaultValues: initialAdminActions,
  });

  const [pagination, setPagination] = useState<GrantsPagination>(
    getValuesFromUrl().pagination,
  );
  const [filters, setFilters] = useState<GrantsFiltersType>(
    getValuesFromUrl().filters,
  );
  const [filtersOpen, setFiltersOpen] = useState<boolean>(false);
  //used to catch case when user has filters selected and they are included in url and user clicks on Forecast nav link in sidebar menu
  const [preventFiltersEffectCall, setPreventFiltersEffectCall] =
    useState<boolean>(false);
  const [columns, setColumns] = useState<Array<any>>([]);

  const [shareModalOpen, setShareModalOpen] = useState<boolean>(false);
  const [shareModalOpenFor, setShareModalOpenFor] = useState<any>(null);

  const [detailsModalOpen, setDetailsModalOpen] = useState<boolean>(false);
  const [detailsModalOpenFor, setDetailsModalOpenFor] = useState<{
    [key: string]: any;
  } | null>(null);

  const [editModalOpen, setEditModalOpen] = useState<boolean>(false);
  const [editModalOpenFor, setEditModalOpenFor] = useState<any>(null);

  const [keywordSearchInputValue, setKeywordSearchInputValue] =
    useState<string>("");

  const [addToPipelinesOpen, setAddToPipelinesOpen] = useState<
    string | number | null
  >(null);

  const stickyColumns = getGrantsStickyColumnsConfig(canShare);

  const adjustSortPropertyNameForRequest = (propertyName: string) => {
    let _propertyName = propertyName;
    switch (propertyName) {
      case "manualLastUpdatedDate":
        _propertyName = "lastUpdatedDate";
        break;
      default:
        break;
    }
    return _propertyName;
  };

  const getDataForRequest = () => {
    return {
      ...pagination,
      ...Object.fromEntries(
        Object.entries(filters)
          .filter(([key, value]) =>
            typeof value === "string" || Array.isArray(value)
              ? value?.length > 0
              : value !== null,
          )
          .map(([key, value]) =>
            Array.isArray(value)
              ? [
                  key,
                  value.map((v: DropdownOption) =>
                    v.hasOwnProperty("value") ? v.value : v,
                  ),
                ]
              : [
                  key,
                  key === "sortField"
                    ? adjustSortPropertyNameForRequest(value)
                    : value,
                ],
          ),
      ),
    };
  };

  const handlePageChange = (
    e: React.MouseEvent<HTMLButtonElement>,
    page: number,
  ): void => {
    setPagination((prev) => ({
      ...prev,
      pageIndex: page,
    }));
  };

  const handleRowsPerPageChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ): void => {
    setPagination((prev) => ({
      ...prev,
      pageIndex: 0,
      pageSize: +e.target.value,
    }));
  };

  const onKeywordChange = (e) => {
    setKeywordSearchInputValue(e.target.value);
    debouncedKeywordChange(e.target.value);
  };

  const searchCallback = (value) => {
    setFilters((prev) => ({ ...prev, filter: value }));
  };

  const debouncedKeywordChange = useCallback(debounce(searchCallback, 750), []);

  const handleOpenEditModal = (item: any): void => {
    setEditModalOpen(true);
    setEditModalOpenFor(item);
    adminActionsSetValue(
      "manualLastUpdatedDate",
      item.manualLastUpdatedDate
        ? getNewDateNoTimezoneAdjustment(item.manualLastUpdatedDate)
        : null,
    );
  };

  const handleCloseEditModal = (): void => {
    setEditModalOpen(false);
    setTimeout(() => {
      setEditModalOpenFor(null);
      adminActionsReset(initialAdminActions);
    }, 150);
  };

  const onAdminActionsSubmit = (values: AdminActionsFormType): void => {
    console.log(values);
    if (!editModalOpenFor?.id) return;
    dispatch(
      updateGrantsRecord({
        context,
        id: editModalOpenFor.id,
        values,
      }),
    );
  };

  const onFiltersSubmit = (values: GrantsFiltersType) => {
    handleInvalidDatesToast(values, () => {
      setFilters((prev) => ({
        ...prev,
        ...Object.fromEntries(
          Object.entries(values)?.filter(
            ([key, value]) =>
              !key.includes("include") &&
              !key.includes("sort") &&
              key !== "onlyUntrackedInPipeline",
          ),
        ),
      }));
      handleCloseFilters();
    });
  };

  const handleOpenFilters = (): void => {
    setFiltersOpen(true);
  };

  const handleCloseFilters = (): void => {
    setFiltersOpen(false);
  };

  const handleCancelFilters = (): void => {
    handleCloseFilters();
    setTimeout(() => {
      reset({
        filter: filters.filter,
        country: filters.country,
        opportunityNumber: filters.opportunityNumber,
        category: filters.category,
        sortField: filters.sortField,
        sortOrder: filters.sortOrder,
        onlyMyList: filters.onlyMyList,
        includeUsaid: filters.includeUsaid,
        includeMcc: filters.includeMcc,
        includeStateDept: filters.includeStateDept,
        includeCdc: filters.includeCdc,
        fileName: filters.fileName,
        fileKeyword: filters.fileKeyword,
        updatedBy: filters.updatedBy,
        status: filters.status,
        onlyUntrackedInPipeline: filters.onlyUntrackedInPipeline,
        eligibility: filters.eligibility,
      });
    }, 150);
  };

  const handleClearFilters = (): void => {
    reset(initialFilters);
  };

  const handleOpenShareModal = (item: any): void => {
    setShareModalOpen(true);
    setShareModalOpenFor(item);
  };

  const onShareSubmit = (values: any) => {
    dispatch(
      shareOpportunities({
        context,
        ...values,
      }),
    );
  };

  const handleOpenDetailsModal = (item: any): void => {
    setDetailsModalOpen(true);
    setDetailsModalOpenFor(item);
  };

  const handleCloseDetailsModal = (): void => {
    setDetailsModalOpen(false);
    setTimeout(() => {
      setDetailsModalOpenFor(null);
    }, 150);
  };

  const adjustPipelinesData = async (): Promise<void> => {
    //   const chunkSize = 10;
    //   const chunksAmount = Math.ceil(rawPipelines.length / chunkSize);
    //   const allPipelines: any = [];
    //   let fetchStatus: string | null = null;
    //
    //   for (let i = 0; i < chunksAmount; ++i) {
    //     const start = i * chunkSize;
    //     const records = await Promise.all(
    //       rawPipelines
    //         .slice(start, start + chunkSize)
    //         .map(async (x: PipelineDto) => {
    //           const sa = await axios.get(
    //             getFullUrl(`/api/pipeline/${x.id}/grant`, {
    //               useDedicatedEnvironment: true,
    //             }),
    //             createAuthenticatedRequest(context),
    //           );
    //           const data = sa.data.data;
    //           return {
    //             id: x.id,
    //             name: x.name,
    //             grants: data,
    //           };
    //         }),
    //     ).catch((err) => {
    //       fetchStatus = RequestStatus.statuses.ERROR;
    //       i = chunksAmount;
    //       return [];
    //     });
    //
    //     allPipelines.push(
    //       records.map((x) => ({
    //         id: x.id,
    //         name: x.name,
    //         grants: x.grants,
    //       })),
    //     );
    //   }

    dispatch(
      updatePipelines({
        pipelines: rawPipelines,
        //   allPipelines.flat(),
        // ...(fetchStatus !== null && { fetchStatus }),
      }),
    );
  };

  const handleSaveColumnsConfiguration = (data: {
    [key: string]: Array<ColumnsSettingsData>;
  }): void => {
    dispatch(
      patchTableSettings({
        context,
        key: GRANTS_COLUMNS_SETTINGS_KEY,
        configuration: data,
      }),
    );
  };

  const handleOpenAddToPipelines = (item: any): void => {
    const { id } = item;
    if (addToPipelinesOpen) {
      handleCloseAddToPipelines();
      setTimeout(() => {
        dispatch(setPipelinesForRecordFor(id));
        dispatch(
          getPipelinesForRecord({
            context,
            table: "grants",
            recordId: id,
          }),
        );
      }, 100);
    } else {
      dispatch(setPipelinesForRecordFor(id));
      dispatch(
        getPipelinesForRecord({
          context,
          table: "grants",
          recordId: id,
        }),
      );
    }
    // setAddToPipelinesOpen(id);
  };

  const handleCloseAddToPipelines = (): void => {
    // dispatch(resetPipelinesForRecord());
    setAddToPipelinesOpen(null);
    dispatch(setPipelinesForRecordFor(null));
    dispatch(resetPipelinesForRecord());
  };

  useEffect(() => {
    if (
      RequestStatus.isDone(pipelinesForRecordFetchStatus) &&
      pipelinesForRecordFor !== null &&
      !addToPipelinesOpen
    )
      setAddToPipelinesOpen(pipelinesForRecordFor);
    if (RequestStatus.isError(pipelinesForRecordFetchStatus)) {
      toast.error(
        "Pipeline data for this row couldn't be loaded. Try again later.",
      );
    }
  }, [pipelinesForRecordFetchStatus, pipelinesForRecordFor]);

  useEffect(() => {
    if (
      RequestStatus.isDone(tableSettingsFetchStatus) ||
      RequestStatus.isError(tableSettingsFetchStatus)
    ) {
      setColumns(
        getGrantsColumnsConfig(getSortableColumnPropertyName, false)
          .filter((column) => (column?.hiddenBasedOnRole ? canEdit : true))
          .map((column, idx) => ({
            ...column,
            order:
              columnsOrder.find(
                (_column) => _column.propertyName === column.propertyName,
              )?.order ?? idx,
            isVisible:
              columnsOrder.find(
                (_column) => _column.propertyName === column.propertyName,
              )?.isVisible ?? column.isVisible,
          }))
          .sort((a, b) => a.order - b.order),
      );
    }
  }, [tableSettingsFetchStatus, getSortableColumnPropertyName]);

  useEffect(() => {
    //  load pipeline data
    dispatch(getPipelines(context));
    dispatch(getTableSettings({ context, key: GRANTS_COLUMNS_SETTINGS_KEY }));
    dispatch(getCountryDropdownOptions({ context }));
    dispatch(getCategoriesDropdownOptions({ context }));

    return () => {
      setColumns([]);
      dispatch(resetTableSettings());
      dispatch(resetShareOpportunitiesStatus());
      dispatch(resetAddOrRemoveFromPipelinesStatus());
      dispatch(resetPipelinesState());
      dispatch(resetPipelinesForRecord());
    };
  }, []);

  useEffect(() => {
    if (
      !RequestStatus.isNull(fetchStatus) &&
      history.location.search.length === 0
    ) {
      setPreventFiltersEffectCall(true);
    } else {
      //todo: define fetching
      dispatch(
        getGrants({
          context,
          params: {
            ...getDataForRequest(),
          },
        }),
      );
    }
  }, [history.location]);

  useEffect(() => {
    if (preventFiltersEffectCall) {
      setPagination(initialPagination);
      setFilters(initialFilters);
      reset(initialFilters);
      setTimeout(() => setPreventFiltersEffectCall(false), 150);
    }
  }, [preventFiltersEffectCall]);

  useEffect(() => {
    if (!RequestStatus.isNull(fetchStatus))
      history.push(
        `/grants${buildQuery({
          ...getDataForRequest(),
        })}`,
      );
  }, [pagination.pageIndex, pagination.pageSize]);

  useEffect(() => {
    if (!RequestStatus.isNull(fetchStatus) && !preventFiltersEffectCall) {
      if (pagination.pageIndex > 0)
        setPagination((prev) => ({ ...prev, pageIndex: 0 }));
      else
        history.push(
          `/grants${buildQuery({
            ...getDataForRequest(),
          })}`,
        );
    }
  }, [filters, preventFiltersEffectCall]);

  useEffect(() => {
    //it differs from regular fetch status value assignment since this requires updating the pipelines with additional data
    if (RequestStatus.isHalfDone(pipelinesFetchStatus)) {
      adjustPipelinesData();
    } else if (RequestStatus.isError(pipelinesFetchStatus)) {
      toast.error(
        "Couldn't load pipelines details for Grants. Try again later.",
      );
      dispatch(resetPipelinesState());
    }
  }, [pipelinesFetchStatus]);

  useEffect(() => {
    if (!RequestStatus.isNull(addOrRemoveFromPipelinesFetchStatus)) {
      if (RequestStatus.isDone(addOrRemoveFromPipelinesFetchStatus))
        toast.success("Pipelines modified");
      else if (RequestStatus.isError(addOrRemoveFromPipelinesFetchStatus))
        toast.error("There was an error performing this operation.");
      dispatch(getPipelines(context));
      addToPipelinesOpen && handleCloseAddToPipelines();
      dispatch(resetAddOrRemoveFromPipelinesStatus());
      dispatch(
        getGrants({
          context,
          params: {
            ...getDataForRequest(),
          },
        }),
      );
    }
  }, [addOrRemoveFromPipelinesFetchStatus]);

  useEffect(() => {
    if (RequestStatus.isDone(shareOpportunitiesFetchStatus)) {
      toast.success("Opportunity shared successfully");
      dispatch(resetShareOpportunitiesStatus());
      setShareModalOpen(false);
    }
    if (RequestStatus.isError(shareOpportunitiesFetchStatus))
      toast.error("There was an error sharing this opportunity.");
  }, [shareOpportunitiesFetchStatus]);

  useEffect(() => {
    if (RequestStatus.isDone(updateGrantsRecordFetchStatus)) {
      toast.success("Record successfully updated");
      dispatch(resetUpdateGrantsRecordState());
      handleCloseEditModal();
      dispatch(
        getGrants({
          context,
          params: {
            ...getDataForRequest(),
          },
        }),
      );
    }
    if (RequestStatus.isError(updateGrantsRecordFetchStatus))
      toast.error("There was an error updating this record.");
  }, [updateGrantsRecordFetchStatus]);

  return (
    <>
      <GrantsDetailsAndFilesModal
        open={detailsModalOpen}
        openFor={detailsModalOpenFor}
        handleCloseDetailsModal={handleCloseDetailsModal}
      />
      <ShareModal
        open={shareModalOpen}
        handleClose={() => setShareModalOpen(false)}
        openFor={shareModalOpenFor}
        onSubmit={onShareSubmit}
        shareFetchStatus={shareOpportunitiesFetchStatus}
        type={"grants"}
      />
      <Drawer
        open={filtersOpen}
        onClose={handleCancelFilters}
        variant={"temporary"}
        anchor={"right"}
        sx={{
          zIndex: constants.zIndexes.drawer,
        }}
        PaperProps={{
          sx: {
            width: "480px",
            "& > div": {
              px: 2,
            },
          },
        }}
      >
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            gap: "16px",
            backgroundColor: MUITheme.palette.secondary.light,
            borderBottom: `solid 3px ${MUITheme.palette.secondary.main}`,
            py: 3,
          }}
        >
          <FiltersIcon />
          <Typography variant={"h5"}>Filters</Typography>
        </Box>
        <Box
          sx={{
            py: 2,
            overflowY: "scroll",
            flex: 1,
          }}
        >
          <form
            onSubmit={handleSubmit(onFiltersSubmit)}
            id={FILTERS_FORM_ID}
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              gap: "20px",
            }}
          >
            <GrantsFilters control={control} />
          </form>
        </Box>
        <Divider />
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "flex-end",
            gap: "16px",
            py: 2,
          }}
        >
          <Button
            variant={"secondaryContained"}
            color={"secondary"}
            onClick={handleCancelFilters}
          >
            Cancel
          </Button>
          <Button
            variant={"secondaryContained"}
            color={"secondary"}
            onClick={handleClearFilters}
          >
            Clear
          </Button>
          <Button
            variant={"contained"}
            color={"secondary"}
            // onClick={handleFilterData}
            type={"submit"}
            form={FILTERS_FORM_ID}
          >
            Save
          </Button>
        </Box>
      </Drawer>
      <SimpleModal
        open={editModalOpen}
        handleClose={handleCloseEditModal}
        acceptButtonType={"submit"}
        form={ADMIN_EDIT_FORM_ID}
        title={`Update data of ${editModalOpenFor?.opportunityTitle}`}
        minWidth={"600px"}
        acceptLabel={"Save"}
        acceptButtonLoading={RequestStatus.isFetching(
          updateGrantsRecordFetchStatus,
        )}
      >
        <Box
          sx={{
            display: "flex",
            gap: 4,
            py: 1,
          }}
        >
          <form
            id={ADMIN_EDIT_FORM_ID}
            onSubmit={adminActionsHandleSubmit(onAdminActionsSubmit)}
            style={{
              width: "100%",
            }}
          >
            <Input
              type={"date"}
              fullWidth
              name={"manualLastUpdatedDate"}
              control={adminActionsControl}
              label={"Last Updated Date"}
            />
          </form>
        </Box>
      </SimpleModal>
      {columns.length > 0 && (
        <CustomTable
          config={{
            data: items.map((item) => ({
              ...item,
              actions: (
                <RowActions
                  onShareClick={() => handleOpenShareModal(item)}
                  item={item}
                  canShare={canShare}
                  onViewDetailsClick={() => handleOpenDetailsModal(item)}
                  addToPipelinesOpen={addToPipelinesOpen}
                  setAddToPipelinesOpen={setAddToPipelinesOpen}
                  handleOpenAddToPipelines={handleOpenAddToPipelines}
                  handleCloseAddToPipelines={handleCloseAddToPipelines}
                />
              ),
              adminActions: (
                <Tooltip title={"Edit"}>
                  <span>
                    <IconButton
                      sx={{
                        border: "solid 2px",
                        backgroundColor: alpha(
                          MUITheme.palette.success.main,
                          0.1,
                        ),
                        position: "unset",
                      }}
                      color={"success"}
                      onClick={() => handleOpenEditModal(item)}
                    >
                      <EditIcon />
                    </IconButton>
                  </span>
                </Tooltip>
              ),
              cfdas: `<div>${(item.cfdas || []).map(
                (v: string) => `<p>${v}</p>`,
              )}</div>`,
              costSharing: item.costSharing ? "YES" : "NO",
              estimatedFundingFormatted: getEmptyStringWithCurrencyIfNull(
                item.estimatedFundingFormatted,
              ),
              awardCeilingFormatted: getEmptyStringWithCurrencyIfNull(
                item.awardCeilingFormatted,
              ),
              awardFloorFormatted: getEmptyStringWithCurrencyIfNull(
                item.awardFloorFormatted,
              ),
              applicantTypeDescription: removeHtmlTags(
                item.applicantTypeDescription,
              ).replace("&ndash;", "–"),
              agencyContactDescription: (item.agencyContactDescription || "")
                .replace(/\&nbsp;/g, "")
                .replace(/&amp;/g, "&")
                .replace(/&ntilde;/g, "ñ"),
            })),
            columns,
            stickyColumns,
            header: getTableHeader(MUITheme, {
              fetchStatus,
              inputValue: keywordSearchInputValue,
              allFilters: {
                pagination,
                filters: Object.fromEntries(
                  Object.entries(filters)
                    .filter(([key, value]) => !key.includes("sort"))
                    .map(([key, value]) => [key, value]),
                ),
                sortOption: {
                  sortField: filters.sortField ?? "",
                  sortOrder: filters.sortOrder ?? 0,
                },
              },
              lastUpdated: lastUpdatedUtc
                ? `${format(
                    new Date(lastUpdatedUtc + (lastUpdatedUtc.endsWith("Z") ? "" : "Z")),
                    "dd MMMM yyyy, HH:mm",
                  )}`
                : "N/A",
              onChange: onKeywordChange,
              handleOpenFilters,
              setFilters,
            }),
            exportExcelFunc: async () =>
              generateExcel(
                "Grants+",
                `Aidkonekt_grants_plus_${new Date().getFullYear()}`,
                getExportableDataTable(
                  getExcelData
                    ? await getExcelData(context, getDataForRequest(), total)
                    : items,
                ),
              ),
            columnsConfiguration: {
              allowColumnsConfiguration: true,
              onSaveColumnsConfiguration: handleSaveColumnsConfiguration,
              columnsSettingsKey: GRANTS_COLUMNS_SETTINGS_KEY,
            },
          }}
          loading={
            RequestStatus.isFetching(fetchStatus) ||
            RequestStatus.isHalfDone(pipelinesFetchStatus) ||
            RequestStatus.isFetching(pipelinesFetchStatus)
          }
          onPageChange={handlePageChange}
          onRowsPerPageChange={handleRowsPerPageChange}
          pagination={{
            page: pagination.pageIndex,
            rowsPerPage: pagination.pageSize,
            total,
          }}
          tableContainerComponentSx={{
            mb: 2,
          }}
          onSortClick={(
            column: DataColumnConfigProps | StickyColumnConfigProps,
          ) =>
            setFilters((prev) => {
              const propertyName = getSortableColumnPropertyName(
                column.propertyName,
              );
              return prev.sortField === propertyName
                ? prev.sortOrder === 0
                  ? { ...prev, sortField: propertyName, sortOrder: 1 }
                  : prev.sortOrder === 1
                  ? { ...prev, sortField: propertyName, sortOrder: -1 }
                  : { ...prev, sortField: "", sortOrder: 0 }
                : { ...prev, sortField: propertyName, sortOrder: 1 };
            })
          }
          currentSort={{
            sortField: filters.sortField ?? "",
            sortOrder: filters.sortOrder ?? 0,
          }}
          disableOpenCellDetailsModal={!!addToPipelinesOpen}
        />
      )}
    </>
  );
};

export default Grants;
