import { createSvgIcon } from "@mui/material/utils";

export const MoreDescriptionIcon = createSvgIcon(
  <svg
    width="16"
    height="6"
    viewBox="0 0 16 6"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M8.75 4.125H1.25C1.05109 4.125 0.860322 4.20402 0.71967 4.34467C0.579018 4.48532 0.5 4.67609 0.5 4.875C0.5 5.07391 0.579018 5.26468 0.71967 5.40533C0.860322 5.54598 1.05109 5.625 1.25 5.625H8.75C8.94891 5.625 9.13968 5.54598 9.28033 5.40533C9.42098 5.26468 9.5 5.07391 9.5 4.875C9.5 4.67609 9.42098 4.48532 9.28033 4.34467C9.13968 4.20402 8.94891 4.125 8.75 4.125ZM14.75 0.375H1.25C1.05109 0.375 0.860322 0.454018 0.71967 0.59467C0.579018 0.735322 0.5 0.926088 0.5 1.125C0.5 1.32391 0.579018 1.51468 0.71967 1.65533C0.860322 1.79598 1.05109 1.875 1.25 1.875H14.75C14.9489 1.875 15.1397 1.79598 15.2803 1.65533C15.421 1.51468 15.5 1.32391 15.5 1.125C15.5 0.926088 15.421 0.735322 15.2803 0.59467C15.1397 0.454018 14.9489 0.375 14.75 0.375Z"
      fill="black"
    />
  </svg>,
  "MoreDescription",
);
