import {
  Box,
  Checkbox,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  Typography,
  useTheme,
} from "@mui/material";
import Button from "@mui/material/Button";
import React, { MouseEvent, useCallback, useMemo, useState } from "react";
import { PrimesImplementerRecord } from "../../../slices/forecast";
import { format } from "date-fns";
import SimpleModal from "../../../components/Modals/SimpleModal";
import {
  constantDimensions,
  ModalContent,
} from "../../../components/MaterialTable/Table";
import { rowsPerPageOptionsSmall } from "../../../utils/constants/gridsOptions";
import { SelectedImplementerWithProjects } from "../ForecastV2";

interface LikelyPrimesImplementerProjectsListProps {
  records: PrimesImplementerRecord[];
  recordsCount: number;
  isAdminTable: boolean;
  onCheckboxClick?: (
    e: MouseEvent<HTMLButtonElement | null>,
    uid: string,
  ) => void;
  onProjectCheckboxClick?: (project: PrimesImplementerRecord) => void;
  // selectedProjects?: string[];
  selectedProjects?: SelectedImplementerWithProjects | null;
  pagination?: { page: number; rowsPerPage: number };
  setPagination?: (pagination: { page: number; rowsPerPage: number }) => void;
  disabledPagination?: boolean;
}

export default function LikelyPrimesImplementerProjectsList({
  records,
  recordsCount,
  isAdminTable,
  onCheckboxClick,
  onProjectCheckboxClick,
  selectedProjects = null,
  pagination: _pagination,
  setPagination: _setPagination,
  disabledPagination = false,
}: LikelyPrimesImplementerProjectsListProps) {
  const theme = useTheme();

  const [modalContent, setModalContent] = useState<ModalContent | null>(null);
  const [modalOpen, setModalOpen] = useState<boolean>(false);

  const pagination = useMemo(() => _pagination, [_pagination]);
  const setPagination = useCallback(
    (pagination: { page: number; rowsPerPage: number }) => {
      if (_setPagination) {
        _setPagination(pagination);
      }
    },
    [_setPagination],
  );

  function handleOpenModal(params: ModalContent): void {
    setModalContent(params);
    setModalOpen(true);
  }

  function handleCloseModal(): void {
    setModalOpen(false);
    setTimeout(() => {
      setModalContent(null);
    }, 150);
  }

  function onPageChange(
    e: MouseEvent<HTMLButtonElement> | null,
    page: number,
  ): void {
    if (!setPagination || !pagination) return;
    setPagination({ ...pagination, page });
  }

  function onRowsPerPageChange(
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ): void {
    if (!setPagination || !pagination) return;
    setPagination({ page: 0, rowsPerPage: +e.target.value });
  }

  return (
    <Box
      sx={{
        p: 2,
        pt: 0,
      }}
    >
      <SimpleModal
        open={modalOpen}
        handleClose={handleCloseModal}
        handleAccept={handleCloseModal}
        disableCancelButton
        title={modalContent?.propertyLabel}
      >
        <Typography
          variant={"body1"}
          dangerouslySetInnerHTML={{ __html: modalContent?.content ?? "" }}
        />
      </SimpleModal>
      <Table
        sx={{
          border: `solid 1px ${theme.palette.divider}`,
        }}
      >
        <TableHead>
          <TableRow>
            {isAdminTable && (
              <TableCell
                width={"40px"}
                sx={{
                  p: 1,
                }}
              ></TableCell>
            )}
            <TableCell width={"120px"} align={"center"}>
              Project
            </TableCell>
            <TableCell width={"40px"} align={"center"}>
              View
            </TableCell>
            <TableCell width={"150px"} align={"center"}>
              Description
            </TableCell>
            <TableCell width={"90px"} align={"center"}>
              Start Date
            </TableCell>
            <TableCell width={"90px"} align={"center"}>
              End Date
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {records.map((project, _index) => (
            <TableRow
              key={`${project.id}-${_index}`}
              sx={{
                backgroundColor:
                  _index % 2
                    ? theme.palette.background.default
                    : theme.palette.background.paper,
              }}
            >
              {isAdminTable && (
                <TableCell
                  width={"40px"}
                  sx={{
                    p: 1,
                  }}
                >
                  <Checkbox
                    sx={{
                      p: 0.5,
                    }}
                    // onClick={(e) => onCheckboxClick?.(e, project.id.toString())}
                    onClick={() => onProjectCheckboxClick?.(project)}
                    // checked={selectedProjects.includes(project.id.toString())}
                    checked={
                      selectedProjects !== null
                        ? selectedProjects.mode === "all"
                          ? true
                          : selectedProjects.mode === "separate-included"
                          ? selectedProjects.projects.find(
                              (p) => p === project.id,
                            ) !== undefined
                          : selectedProjects.mode === "separate-excluded"
                          ? selectedProjects.projects.find(
                              (p) => p === project.id,
                            ) === undefined
                          : false
                        : false
                    }
                  />
                </TableCell>
              )}
              <TableCell
                width={"120px"}
                align={"center"}
                onClick={() =>
                  handleOpenModal({
                    propertyName: "projectName",
                    propertyLabel: "Project Name",
                    content: project.projectName ?? "N/A",
                  })
                }
                sx={{
                  cursor: "pointer",
                }}
              >
                <Typography
                  variant={"subtitle2"}
                  fontWeight={400}
                  color={"inherit"}
                  sx={{
                    display: "-webkit-box",
                    overflow: "hidden",
                    WebkitBoxOrient: "vertical",
                    WebkitLineClamp: 1,
                    wordBreak: "break-all",
                  }}
                  dangerouslySetInnerHTML={{
                    __html: project.projectName ?? "N/A",
                  }}
                />
              </TableCell>
              <TableCell width={"40px"} align={"center"}>
                <Button
                  variant={"contained"}
                  color={"secondary"}
                  size={"small"}
                  sx={{
                    borderRadius: "8px",
                    py: 0,
                    px: 1,
                    minWidth: "unset",
                    "&:hover": {
                      backgroundColor: theme.palette.secondary.dark,
                      color: theme.palette.secondary.contrastText,
                    },
                  }}
                  component={"a"}
                  href={project.view ?? ""}
                  target={"_blank"}
                  rel={"noopener noreferrer"}
                  disabled={!project.view}
                >
                  View
                </Button>
              </TableCell>
              <TableCell
                width={"150px"}
                align={"center"}
                onClick={() =>
                  handleOpenModal({
                    propertyName: "description",
                    propertyLabel: "Description",
                    content: project.description ?? "N/A",
                  })
                }
                sx={{
                  cursor: "pointer",
                }}
              >
                <Typography
                  variant={"subtitle2"}
                  fontWeight={400}
                  color={"inherit"}
                  sx={{
                    display: "-webkit-box",
                    overflow: "hidden",
                    WebkitBoxOrient: "vertical",
                    WebkitLineClamp: 1,
                    wordBreak: "break-all",
                  }}
                  dangerouslySetInnerHTML={{
                    __html: project.description ?? "N/A",
                  }}
                />
              </TableCell>
              <TableCell width={"90px"} align={"center"}>
                <Typography
                  variant={"subtitle2"}
                  fontWeight={400}
                  color={"inherit"}
                >
                  {project.startDate
                    ? format(new Date(project.startDate), "MMM dd, yyyy")
                    : "N/A"}
                </Typography>
              </TableCell>
              <TableCell width={"90px"} align={"center"}>
                <Typography
                  variant={"subtitle2"}
                  fontWeight={400}
                  color={"inherit"}
                >
                  {project.endDate
                    ? format(new Date(project.endDate), "MMM dd, yyyy")
                    : "N/A"}
                </Typography>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
      {isAdminTable && pagination && (
        <Box
          sx={{
            backgroundColor: theme.palette.background.paper,
            border: `solid 1px ${theme.palette.divider}`,
            borderTop: "unset",
          }}
        >
          <TablePagination
            key={recordsCount}
            count={recordsCount}
            page={pagination.page}
            rowsPerPage={pagination.rowsPerPage}
            rowsPerPageOptions={rowsPerPageOptionsSmall}
            onRowsPerPageChange={onRowsPerPageChange}
            onPageChange={onPageChange}
            component={"div"}
            sx={{
              mx: 2,
              height: constantDimensions.pagination.height,
            }}
            SelectProps={{
              disabled: disabledPagination,
            }}
            backIconButtonProps={
              disabledPagination ? { disabled: true } : undefined
            }
            nextIconButtonProps={
              disabledPagination ? { disabled: true } : undefined
            }
          />
        </Box>
      )}
    </Box>
  );
}
