import {
  Box,
  Checkbox,
  CircularProgress,
  Collapse,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  Tooltip,
  Typography,
  useTheme,
} from "@mui/material";
import Button from "@mui/material/Button";
import LikelyPrimesImplementerProjectsList from "./LikelyPrimesImplementerProjectsList";
import { RequestStatus } from "../../../utils/Helpers/fetchStatus";
import React, {
  ChangeEvent,
  Dispatch,
  MouseEvent,
  SetStateAction,
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import {
  getAdminPrimesImplementerProjectsList,
  getPrimesImplementerProjectsList,
  PrimesImplementer,
  PrimesImplementerRecord,
} from "../../../slices/forecast";
import { ExpandLess } from "@mui/icons-material";
import { ForecastRecord } from "../../../utils/types/Forecast";
import { useDispatch } from "../../../store";
import UserContext from "../../../services/UserContext";
import { constantDimensions } from "../../../components/MaterialTable/Table";
import { rowsPerPageOptionsSmall } from "../../../utils/constants/gridsOptions";
import { differenceWith, fromPairs, isEqual, toPairs } from "lodash";
import { is } from "date-fns/locale";
import { SelectedImplementerWithProjects } from "../ForecastV2";

interface LikelyPrimesImplementersTableProps {
  tableOpen: boolean;
  implementers: PrimesImplementer[];
  total: number;
  pagination?: {
    page: number;
    rowsPerPage: number;
  };
  setPagination?: Dispatch<
    SetStateAction<{
      page: number;
      rowsPerPage: number;
    }>
  >;
  isProUser: boolean;
  openFor: ForecastRecord | null;
  fetchStatus: string;
  detailsFetchStatus: string;
  isAdminTable: boolean;
  selectedImplementers?: SelectedImplementerWithProjects[];
  setSelectedImplementers?: Dispatch<
    SetStateAction<SelectedImplementerWithProjects[]>
  >;
}

export default function LikelyPrimesImplementersTable({
  tableOpen,
  implementers,
  total,
  pagination,
  setPagination,
  isProUser,
  openFor,
  fetchStatus,
  detailsFetchStatus,
  isAdminTable,
  selectedImplementers,
  setSelectedImplementers,
}: LikelyPrimesImplementersTableProps) {
  const dispatch = useDispatch();
  const context = useContext(UserContext);

  const theme = useTheme();

  const [projectsOpenFor, setProjectsOpenFor] = useState<string[]>([]);
  const [implementersSelected, setImplementersSelected] = useState<string[]>(
    [],
  );
  const [projectsSelected, setProjectsSelected] = useState<string[]>([]);

  const [detailsLoadingFor, setDetailsLoadingFor] = useState<string | null>(
    null,
  );

  /**
   * state containing pagination data for all of the nested projects to allow fetching and paginating them from this file, each pagination data is described by the key of the object, which is a uid of an implementer created from id, country and technical sector values
   */
  const [projectsPaginations, setProjectPaginations] = useState<
    Record<
      string,
      {
        page: number;
        rowsPerPage: number;
        uid: string;
        source: "usa-spending" | "foreign-assistance";
        id: number;
        country?: string;
        technicalSector?: string;
      }
    >
  >({});

  const projectsPaginationsSnapshotRef = useRef<
    Record<
      string,
      {
        page: number;
        rowsPerPage: number;
        uid: string;
        source: "usa-spending" | "foreign-assistance";
        id: number;
        country?: string;
        technicalSector?: string;
      }
    >
  >(projectsPaginations);

  function onPageChange(
    e: MouseEvent<HTMLButtonElement> | null,
    page: number,
  ): void {
    if (!setPagination) return;
    setPagination((prev) => ({
      ...prev,
      page,
    }));
  }

  function onRowsPerPageChange(
    e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ): void {
    if (!setPagination) return;
    setPagination((prev) => ({
      ...prev,
      page: 0,
      rowsPerPage: +e.target.value,
    }));
    setProjectsOpenFor([]);
  }

  function fetchAdminProjects(
    page: number,
    rowsPerPage: number,
    uid: string,
    source: "usa-spending" | "foreign-assistance",
    implementerId: number,
    country?: string,
    technicalSector?: string,
  ) {
    if (!openFor) return;
    setDetailsLoadingFor(uid);
    dispatch(
      getAdminPrimesImplementerProjectsList({
        context,
        source,
        forecastId: openFor.id,
        implementerId,
        uid,
        params: {
          country,
          technicalSector,
          pageIndex: page,
          pageSize: rowsPerPage,
        },
      }),
    );
  }

  function onViewClick(
    implementerId: number,
    source: "usa-spending" | "foreign-assistance",
    uid: string,
    country: string,
    technicalSector?: string,
  ) {
    if (!openFor) return;
    if (projectsOpenFor.includes(uid))
      setProjectsOpenFor(projectsOpenFor.filter((_uid) => _uid !== uid));
    else {
      // setProjectsOpenFor([...projectsOpenFor, uid]);

      if (isAdminTable) {
        fetchAdminProjects(
          0,
          rowsPerPageOptionsSmall[1],
          uid,
          source,
          implementerId,
          country,
          technicalSector,
        );
      } else {
        setDetailsLoadingFor(uid);
        dispatch(
          getPrimesImplementerProjectsList({
            context,
            source,
            forecastId: openFor.rowId,
            implementerId,
            country,
            technicalSector,
          }),
        );
      }
    }
  }

  function onCheckboxClick(
    e: MouseEvent<HTMLButtonElement | null>,
    type: "implementer" | "project",
    uid: string,
  ) {
    const setter =
      type === "implementer" ? setImplementersSelected : setProjectsSelected;
    setter((prev) => {
      if (prev.includes(uid)) return prev.filter((i) => i !== uid);
      return [...prev, uid];
    });
  }

  function onImplementerCheckboxClick(implementer: PrimesImplementer) {
    if (!selectedImplementers || !setSelectedImplementers) return;
    setSelectedImplementers((prev) => {
      const newEntry: SelectedImplementerWithProjects = {
        implementerName: `${implementer.country ?? ""} - ${
          implementer.implementerName
        }`,
        implementerId: implementer.id,
        implementerUid: implementer.uid,
        implementerRecordsCount: implementer.recordsCount,
        mode: "all",
        source: implementer.source,
        country: implementer.country ?? "",
        technicalSector: implementer.technicalSector,
        projects: [],
        displayNames: [],
      };

      const existingEntry = prev.find(
        (i) => i.implementerUid === implementer.uid,
      );

      if (existingEntry) {
        if (existingEntry.mode !== "all") {
          return prev.map((i) =>
            i.implementerUid === implementer.uid ? newEntry : i,
          );
        } else {
          return prev.filter((i) => i.implementerUid !== implementer.uid);
        }
      }
      return [...prev, newEntry];
    });
  }

  /**
   * Function to handle checkbox click on a project row
   * Cases:
   *  - if the implementer is not selected, select it and add the project to the selected implementers list
   *  - if the implementer is selected and the mode is "all", change the mode to "separate-excluded" and add the project to the projects list
   *  - if the implementer is selected and the mode is "separate-included", add the project to the projects list
   */
  const onProjectCheckboxClick = useCallback(
    (implementer: PrimesImplementer, project: PrimesImplementerRecord) => {
      if (!selectedImplementers || !setSelectedImplementers) return;
      setSelectedImplementers((prev) => {
        const existingImplementerEntry = prev.find(
          (i) => i.implementerUid === implementer.uid,
        );

        if (!existingImplementerEntry) {
          return [
            ...prev,
            {
              implementerName: `${implementer.country ?? ""} - ${
                implementer.implementerName
              }`,
              implementerId: implementer.id,
              implementerUid: implementer.uid,
              implementerRecordsCount: implementer.recordsCount,
              mode: "separate-included",
              source: implementer.source,
              country: implementer.country ?? "",
              technicalSector: implementer.technicalSector,
              projects: [project.id],
              displayNames: [
                {
                  projectId: project.id,
                  projectName: project.projectName ?? project.description,
                },
              ],
            },
          ];
        }

        const isProjectIncludedInList =
          existingImplementerEntry.projects.find((p) => p === project.id) !==
          undefined;

        if (existingImplementerEntry.mode === "separate-included") {
          if (
            !isProjectIncludedInList &&
            existingImplementerEntry.projects.length ===
              implementer.recordsCount - 1
          ) {
            return prev.map((i) => {
              if (i.implementerUid === implementer.uid) {
                return {
                  ...i,
                  implementerRecordsCount: implementer.recordsCount,
                  mode: "all",
                  projects: [...i.projects, project.id],
                  displayNames: [
                    ...i.displayNames,
                    {
                      projectId: project.id,
                      projectName: project.projectName ?? project.description,
                    },
                  ],
                };
              }
              return i;
            });
          }

          if (
            isProjectIncludedInList &&
            existingImplementerEntry.projects.length === 1
          ) {
            return prev.filter((i) => i.implementerUid !== implementer.uid);
          }

          return prev.map((i) => {
            if (i.implementerUid === implementer.uid) {
              return {
                ...i,
                implementerRecordsCount: implementer.recordsCount,
                projects: isProjectIncludedInList
                  ? i.projects.filter((p) => p !== project.id)
                  : [...i.projects, project.id],
                displayNames: isProjectIncludedInList
                  ? [
                      ...i.displayNames.filter(
                        (p) => p.projectId !== project.id,
                      ),
                    ]
                  : [
                      ...i.displayNames,
                      {
                        projectId: project.id,
                        projectName: project.projectName ?? project.description,
                      },
                    ],
              };
            }
            return i;
          });
        }

        if (existingImplementerEntry.mode === "separate-excluded") {
          if (
            isProjectIncludedInList &&
            existingImplementerEntry.projects.length === 1
          ) {
            return prev.map((i) => {
              if (i.implementerUid === implementer.uid) {
                return {
                  ...i,
                  implementerRecordsCount: implementer.recordsCount,
                  mode: "all",
                  projects: [],
                  displayNames: [
                    ...i.displayNames,
                    {
                      projectId: project.id,
                      projectName: project.projectName ?? project.description,
                    },
                  ],
                };
              }
              return i;
            });
          }

          if (
            !isProjectIncludedInList &&
            existingImplementerEntry.projects.length ===
              implementer.recordsCount - 1
          ) {
            return prev.filter((i) => i.implementerUid !== implementer.uid);
          }

          return prev.map((i) => {
            if (i.implementerUid === implementer.uid) {
              return {
                ...i,
                implementerRecordsCount: implementer.recordsCount,
                projects: isProjectIncludedInList
                  ? i.projects.filter((p) => p !== project.id)
                  : [...i.projects, project.id],
                displayNames: !isProjectIncludedInList
                  ? [
                      ...i.displayNames.filter(
                        (p) => p.projectId !== project.id,
                      ),
                    ]
                  : [
                      ...i.displayNames,
                      {
                        projectId: project.id,
                        projectName: project.projectName ?? project.description,
                      },
                    ],
              };
            }
            return i;
          });
        }

        return prev.map((i) => {
          if (i.implementerUid === implementer.uid) {
            return {
              ...i,
              implementerRecordsCount: implementer.recordsCount,
              mode: "separate-excluded",
              projects: [project.id],
              displayNames: i.displayNames.filter(
                (p) => p.projectId !== project.id,
              ),
            };
          }
          return i;
        });
      });
    },
    [setSelectedImplementers, selectedImplementers],
  );

  useEffect(() => {
    if (
      RequestStatus.isDone(detailsFetchStatus) &&
      detailsLoadingFor !== null
    ) {
      setProjectsOpenFor([...projectsOpenFor, detailsLoadingFor]);
      setDetailsLoadingFor(null);
    }
  }, [detailsFetchStatus, detailsLoadingFor]);

  useEffect(() => {
    const shouldFetchProjects = !isEqual(
      projectsPaginationsSnapshotRef.current,
      projectsPaginations,
    );
    if (!shouldFetchProjects) return;
    const diff = fromPairs(
      differenceWith(
        toPairs(projectsPaginations),
        toPairs(projectsPaginationsSnapshotRef.current),
        isEqual,
      ),
    );
    const project = Object.values(diff)[0];
    fetchAdminProjects(
      project.page,
      project.rowsPerPage,
      project.uid,
      project.source,
      project.id,
      project.country,
      project.technicalSector,
    );
    projectsPaginationsSnapshotRef.current = projectsPaginations;
  }, [projectsPaginations]);

  function RegularRow({
    implementer,
    _index,
  }: {
    implementer: PrimesImplementer;
    _index: number;
  }) {
    return (
      <>
        <TableRow
          key={implementer.id}
          sx={{
            "& > td": {
              borderBottom: "unset",
              backgroundColor:
                _index % 2
                  ? theme.palette.background.default
                  : theme.palette.background.paper,
            },
          }}
        >
          <TableCell>
            <Typography
              variant={"subtitle2"}
              fontWeight={400}
              color={"inherit"}
              sx={{
                display: "-webkit-box",
                overflow: "hidden",
                WebkitBoxOrient: "vertical",
                WebkitLineClamp: 1,
                wordBreak: "break-all",
              }}
              dangerouslySetInnerHTML={{
                __html: implementer.implementerName,
              }}
            />
          </TableCell>
          <TableCell width={"120px"} align={"center"}>
            <Typography
              variant={"subtitle2"}
              fontWeight={400}
              color={"inherit"}
            >
              {implementer.recordsCount ?? "N/A"}
              {implementer.recordsCount
                ? ` Project${implementer.recordsCount === 1 ? "" : "s"}`
                : ""}
            </Typography>
          </TableCell>
          <TableCell width={"120px"} align={"center"}>
            <Tooltip
              title={
                <Box>
                  <Typography variant={"subtitle2"} color={"inherit"}>
                    This feature is available for <b>PRO</b> subscribers only
                  </Typography>
                  <Typography
                    variant={"subtitle2"}
                    color={"inherit"}
                    component={"a"}
                    href={"/tiers"}
                    sx={{
                      textDecoration: "underline",
                    }}
                  >
                    Upgrade now
                  </Typography>
                </Box>
              }
              disableFocusListener={isProUser}
              disableHoverListener={isProUser}
              disableTouchListener={isProUser}
              disableInteractive={false}
            >
              <span>
                <Button
                  disabled={
                    !isProUser || RequestStatus.isFetching(detailsFetchStatus)
                  }
                  variant={"contained"}
                  color={"secondary"}
                  size={"small"}
                  sx={{
                    borderRadius: "8px",
                    py: 0,
                    px: 1,
                    minWidth: "unset",
                  }}
                  onClick={() =>
                    onViewClick(
                      implementer.id,
                      implementer.source,
                      implementer.uid,
                      implementer.country,
                      implementer.technicalSector,
                    )
                  }
                >
                  {projectsOpenFor.includes(implementer.uid) ? "Hide" : "View"}
                </Button>
              </span>
            </Tooltip>
          </TableCell>
        </TableRow>
        <TableRow>
          <TableCell
            colSpan={3}
            sx={{
              p: 0,
              backgroundColor:
                _index % 2
                  ? theme.palette.background.default
                  : theme.palette.background.paper,
            }}
          >
            <Collapse in={projectsOpenFor.includes(implementer.uid)}>
              <LikelyPrimesImplementerProjectsList
                records={implementer.records}
                recordsCount={implementer.recordsCount ?? 0}
                isAdminTable={isAdminTable}
              />
            </Collapse>
          </TableCell>
        </TableRow>
      </>
    );
  }

  function AdminRow({
    implementer,
    _index,
    rowExpanded,
  }: {
    implementer: PrimesImplementer;
    _index: number;
    rowExpanded: boolean;
  }) {
    const selectedImplementersEntry = selectedImplementers?.find(
      (i) => i.implementerUid === implementer.uid,
    );

    const checked =
      selectedImplementersEntry !== undefined &&
      (selectedImplementersEntry.mode === "all" ||
        (selectedImplementersEntry.mode === "separate-included" &&
          selectedImplementersEntry.projects.length ===
            implementer.recordsCount));
    const indeterminate =
      selectedImplementersEntry !== undefined &&
      ((selectedImplementersEntry.mode === "separate-included" &&
        selectedImplementersEntry.projects.length > 0 &&
        selectedImplementersEntry.projects.length < implementer.recordsCount) ||
        (selectedImplementersEntry.mode === "separate-excluded" &&
          selectedImplementersEntry.projects.length > 0 &&
          selectedImplementersEntry.projects.length <
            implementer.recordsCount));

    return (
      <>
        <TableRow
          key={`${implementer.id}-${_index}`}
          sx={{
            "& > td": {
              borderBottom: "unset",
              backgroundColor:
                _index % 2
                  ? theme.palette.background.default
                  : theme.palette.background.paper,
            },
          }}
        >
          <TableCell
            width={"40px"}
            sx={{
              p: 1,
            }}
          >
            <Checkbox
              sx={{
                p: 0.5,
              }}
              // onClick={(e) =>
              //   onCheckboxClick(e, "implementer", implementer.uid)
              // }
              onClick={() => onImplementerCheckboxClick(implementer)}
              // checked={implementersSelected.includes(implementer.uid)}
              checked={checked}
              indeterminate={indeterminate}
            />
          </TableCell>
          <TableCell width={"40px"}>
            <IconButton
              onClick={() =>
                onViewClick(
                  implementer.id,
                  implementer.source,
                  implementer.uid,
                  implementer.country,
                  implementer.technicalSector,
                )
              }
              disabled={
                RequestStatus.isFetching(fetchStatus) ||
                RequestStatus.isFetching(detailsFetchStatus)
              }
            >
              <ExpandLess
                sx={{
                  transition: "transform 250ms ease-in-out",
                  transform: `rotate(${rowExpanded ? "0deg" : "90deg"})`,
                }}
              />
            </IconButton>
            <Box
              sx={{
                position: "relative",
              }}
            >
              <Box
                sx={{
                  position: "absolute",
                  top: "-25px",
                  left: "6px",
                }}
              >
                {RequestStatus.isFetching(detailsFetchStatus) &&
                  detailsLoadingFor === implementer.uid && (
                    <CircularProgress size={18} />
                  )}
              </Box>
            </Box>
          </TableCell>
          <TableCell width={"120px"}>
            <Typography
              variant={"subtitle2"}
              fontWeight={400}
              color={"inherit"}
              sx={{
                display: "-webkit-box",
                overflow: "hidden",
                WebkitBoxOrient: "vertical",
                WebkitLineClamp: 1,
                wordBreak: "break-all",
              }}
              dangerouslySetInnerHTML={{
                __html: implementer.country ?? "",
              }}
            />
          </TableCell>
          <TableCell>
            <Typography
              variant={"subtitle2"}
              fontWeight={400}
              color={"inherit"}
              sx={{
                display: "-webkit-box",
                overflow: "hidden",
                WebkitBoxOrient: "vertical",
                WebkitLineClamp: 1,
                wordBreak: "break-all",
              }}
              dangerouslySetInnerHTML={{
                __html: implementer.implementerName,
              }}
            />
          </TableCell>
          <TableCell width={"120px"} align={"center"}>
            <Typography
              variant={"subtitle2"}
              fontWeight={400}
              color={"inherit"}
            >
              {implementer.recordsCount ?? 0}
            </Typography>
          </TableCell>
          <TableCell width={"120px"} align={"center"}>
            <Button
              disabled={!isProUser}
              variant={"contained"}
              color={"primary"}
              size={"small"}
              sx={{
                borderRadius: "8px",
                py: 0,
                px: 1,
                minWidth: "unset",
              }}
              onClick={() => onImplementerCheckboxClick(implementer)}
            >
              Suggest{" "}
              <ExpandLess
                sx={{
                  transition: "transform 250ms ease-in-out",
                  transform: `rotate(90deg)`,
                  height: "16px !important",
                  width: "16px !important",
                }}
              />
            </Button>
          </TableCell>
        </TableRow>
        {projectsOpenFor.includes(implementer.uid) && (
          <TableRow key={`${implementer.id}-${_index}-projects`}>
            <TableCell
              colSpan={6}
              sx={{
                p: 0,
                backgroundColor:
                  _index % 2
                    ? theme.palette.background.default
                    : theme.palette.background.paper,
              }}
            >
              <LikelyPrimesImplementerProjectsList
                records={implementer.records}
                recordsCount={implementer.recordsCount ?? 0}
                isAdminTable={isAdminTable}
                onCheckboxClick={(e, uid: string) =>
                  onCheckboxClick(e, "project", uid)
                }
                onProjectCheckboxClick={(project: PrimesImplementerRecord) =>
                  onProjectCheckboxClick(implementer, project)
                }
                // selectedProjects={projectsSelected}
                selectedProjects={
                  selectedImplementers?.find(
                    (i) => i.implementerUid === implementer.uid,
                  ) ?? null
                }
                pagination={
                  projectsPaginations[implementer.uid] ?? {
                    page: 0,
                    rowsPerPage: rowsPerPageOptionsSmall[1],
                  }
                }
                setPagination={(pagination: {
                  page: number;
                  rowsPerPage: number;
                }) =>
                  setProjectPaginations((prev) => ({
                    ...prev,
                    [implementer.uid]: {
                      ...prev[implementer.uid],
                      uid: implementer.uid,
                      source: implementer.source,
                      id: implementer.id,
                      country: implementer.country,
                      technicalSector: implementer.technicalSector,
                      ...pagination,
                    },
                  }))
                }
                disabledPagination={
                  RequestStatus.isFetching(fetchStatus) ||
                  RequestStatus.isFetching(detailsFetchStatus)
                }
              />
            </TableCell>
          </TableRow>
        )}
      </>
    );
  }

  return (
    <Box
      sx={{
        width: "100%",
        border: `solid 1px ${theme.palette.divider}`,
        borderTop: "unset",
        ...(isAdminTable && {
          overflow: "scroll",
        }),
      }}
    >
      <Collapse in={tableOpen}>
        <Table
          sx={{
            ...(isAdminTable && {
              tableLayout: "fixed",
              borderCollapse: "separate",
            }),
          }}
        >
          <TableHead
            sx={{
              ...(isAdminTable && {
                position: "sticky",
                top: 0,
                zIndex: 1,
                height: "40px",
              }),
            }}
          >
            <TableRow>
              {isAdminTable && (
                <TableCell
                  width={"40px"}
                  sx={{
                    p: 1,
                  }}
                ></TableCell>
              )}
              {isAdminTable && <TableCell width={"40px"}></TableCell>}
              {isAdminTable && (
                <TableCell width={"120px"}>Office/Country</TableCell>
              )}
              <TableCell>Implementer</TableCell>
              <TableCell width={"120px"} align={"center"}>
                Count
              </TableCell>
              <TableCell width={"120px"} align={"center"}>
                {isAdminTable ? "Actions" : "View Projects"}
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {implementers.length > 0 ? (
              implementers.map((implementer, _index) =>
                isAdminTable ? (
                  <AdminRow
                    key={`${implementer.id}-${_index}`}
                    implementer={implementer}
                    _index={_index}
                    rowExpanded={projectsOpenFor.includes(implementer.uid)}
                  />
                ) : (
                  <RegularRow
                    key={`${implementer.id}-${_index}`}
                    implementer={implementer}
                    _index={_index}
                  />
                ),
              )
            ) : RequestStatus.isFetching(fetchStatus) ? (
              <TableRow>
                <TableCell colSpan={isAdminTable ? 6 : 3}>
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <CircularProgress />
                  </Box>
                </TableCell>
              </TableRow>
            ) : (
              RequestStatus.isDone(fetchStatus) && (
                <TableRow>
                  <TableCell colSpan={isAdminTable ? 6 : 3}>
                    <Typography variant={"subtitle2"} color={"inherit"}>
                      No implementers found
                    </Typography>
                  </TableCell>
                </TableRow>
              )
            )}
          </TableBody>
        </Table>
      </Collapse>
      {isAdminTable && pagination && (
        <Box
          sx={{
            position: "sticky",
            bottom: 0,
            backgroundColor: theme.palette.background.paper,
            borderTop: `solid 1px ${theme.palette.divider}`,
          }}
        >
          <TablePagination
            count={total}
            page={pagination.page}
            rowsPerPage={pagination.rowsPerPage}
            rowsPerPageOptions={rowsPerPageOptionsSmall}
            onPageChange={onPageChange}
            onRowsPerPageChange={onRowsPerPageChange}
            component={"div"}
            sx={{
              mx: 2,
              height: constantDimensions.pagination.height,
            }}
            SelectProps={{
              disabled:
                RequestStatus.isFetching(fetchStatus) ||
                RequestStatus.isFetching(detailsFetchStatus),
            }}
            backIconButtonProps={
              RequestStatus.isFetching(fetchStatus) ||
              RequestStatus.isFetching(detailsFetchStatus)
                ? { disabled: true }
                : undefined
            }
            nextIconButtonProps={
              RequestStatus.isFetching(fetchStatus) ||
              RequestStatus.isFetching(detailsFetchStatus)
                ? { disabled: true }
                : undefined
            }
          />
        </Box>
      )}
    </Box>
  );
}
