import {
  Avatar,
  Box,
  Grid,
  IconButton,
  TextField,
  Tooltip,
  useTheme,
} from "@mui/material";
import Input from "../../../components/Widgets/Inputs/Input";
import React, { useCallback, useMemo, useState } from "react";
import { debounce } from "lodash";
import SearchIcon from "@mui/icons-material/Search";
import ClearIcon from "@mui/icons-material/Clear";
import { FiltersIcon } from "../../../components/Icons/FiltersIcon";
import { useSelector } from "../../../store";

interface ManageOpportunitiesFiltersProps {
  control: any;
  setSearchValue: (value: string) => void;
  loading: boolean;
  fieldsValues: any;
}

export default function ManageOpportunitiesFilters({
  control,
  setSearchValue,
  loading,
  fieldsValues,
}: ManageOpportunitiesFiltersProps) {
  const theme = useTheme();

  const {
    dropdownOptions: { usaSpending, foreignAssistance, fetchStatus },
  } = useSelector((state) => state.forecast.primes.adminViewImplementers);

  const [keywordSearchInputValue, setKeywordSearchInputValue] =
    useState<string>("");

  const isFA = useMemo(
    () => fieldsValues.source === "foreign-assistance",
    [fieldsValues.source],
  );

  function onKeywordChange(e) {
    setKeywordSearchInputValue(e.target.value);
    debouncedKeywordChange(e.target.value);
  }

  function searchCallback(value: string) {
    setSearchValue(value);
  }

  const debouncedKeywordChange = useCallback(debounce(searchCallback, 750), []);

  return (
    <Grid container spacing={1} sx={{ my: 1 }}>
      <Grid item xs={12} md={6} lg={3}>
        <Input
          fullWidth
          size={"small"}
          type={"combo-box"}
          name={"source"}
          control={control}
          // defaultValue={null}
          // label={"CONTEXT"}
          options={[
            {
              value: "usa-spending",
              label: "USA Spending",
            },
            {
              value: "foreign-assistance",
              label: "Foreign Assistance",
            },
          ]}
          disabled={loading}
          disableClearable
        />
      </Grid>
      <Grid item xs={12} md={6} lg={3}>
        <TextField
          value={keywordSearchInputValue}
          onChange={onKeywordChange}
          fullWidth
          InputProps={{
            startAdornment: (
              <SearchIcon sx={{ color: theme.palette.action.active }} />
            ),
            endAdornment: (
              <>
                {keywordSearchInputValue?.length > 0 && (
                  <IconButton
                    onClick={() => onKeywordChange({ target: { value: "" } })}
                    sx={{ mr: 0.5 }}
                  >
                    <ClearIcon />
                  </IconButton>
                )}
              </>
            ),
            sx: {
              px: 0.5,
            },
          }}
          size={"small"}
          placeholder={"Search"}
          disabled={loading}
        />
      </Grid>
      <Grid item xs={12} md={6} lg={3}>
        <Input
          fullWidth
          size={"small"}
          type={"combo-box"}
          name={"implementer"}
          control={control}
          // defaultValue={null}
          label={"Implementer"}
          disabled={loading}
          useVirtualizedList
          options={
            isFA ? foreignAssistance.implementors : usaSpending.implementors
          }
        />
      </Grid>
      <Grid item xs={12} md={6} lg={3}>
        <Input
          fullWidth
          size={"small"}
          type={"combo-box"}
          name={"country"}
          control={control}
          // defaultValue={null}
          label={"Country / Office"}
          disabled={loading}
          useVirtualizedList
          options={isFA ? foreignAssistance.countries : usaSpending.countries}
        />
      </Grid>
      {/*<Grid item xs={12} md={12} lg={6}>*/}
      {/*  <Input*/}
      {/*    fullWidth*/}
      {/*    size={"small"}*/}
      {/*    type={"text"}*/}
      {/*    name={"keywords"}*/}
      {/*    control={control}*/}
      {/*    // defaultValue={null}*/}
      {/*    disabled={loading}*/}
      {/*    label={"Keywords"}*/}
      {/*  />*/}
      {/*</Grid>*/}
      <Grid item xs={12} md={6} lg={3}>
        <Input
          fullWidth
          size={"small"}
          type={"date"}
          name={"closingDate"}
          control={control}
          // defaultValue={null}
          disabled={loading}
          label={"Closing Date"}
          dateChangeOnAccept
        />
      </Grid>
      {isFA && (
        <Grid item xs={12} md={6} lg={3}>
          <Input
            fullWidth
            size={"small"}
            type={"combo-box"}
            name={"fundingAgency"}
            control={control}
            // defaultValue={null}
            disabled={loading}
            label={"Funding Agency"}
            useVirtualizedList
            options={foreignAssistance.fundingAgencies}
          />
        </Grid>
      )}
      {isFA && (
        <Grid item xs={12} md={6} lg={3}>
          <Input
            fullWidth
            size={"small"}
            type={"combo-box"}
            name={"technicalSector"}
            control={control}
            // defaultValue={null}
            disabled={loading}
            label={"Technical Sector"}
            useVirtualizedList
            options={foreignAssistance.technicalSectors}
          />
        </Grid>
      )}
      {isFA && (
        <Grid item xs={12} md={6} lg={3}>
          <Input
            fullWidth
            size={"small"}
            type={"combo-box"}
            name={"awardFundingAgency"}
            control={control}
            // defaultValue={null}
            disabled={loading}
            label={"Award Funding Agency"}
            useVirtualizedList
            options={foreignAssistance.fundingAccounts}
          />
        </Grid>
      )}
    </Grid>
  );
}
