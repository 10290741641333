import axios from "axios";
import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import {
  createAuthenticatedRequest,
  createRequestWithAuthHeaders,
  getFullUrl,
} from "../configs/axios-export.custom";
import { UserContextType } from "../services/UserContext";
import { PipelineDto } from "../utils/types/Forecast";
import { RequestStatus } from "../utils/Helpers/fetchStatus";

interface PipelinesState {
  rawItems: Array<any>;
  items: Array<any>;
  fetchStatus: string;
  pipelinesForRecord: {
    items: Array<{
      pipelineName: string;
      pipelineId: number;
    }>;
    itemsFor: string | number | null;
    fetchStatus: string;
  };
  addOrRemoveFromPipelines: {
    postFetchStatus: string;
  };
}

const initialState: PipelinesState = Object.freeze({
  rawItems: [],
  items: [],
  fetchStatus: RequestStatus.statuses.NULL,
  pipelinesForRecord: {
    items: [],
    itemsFor: null,
    fetchStatus: RequestStatus.statuses.NULL,
  },
  addOrRemoveFromPipelines: {
    postFetchStatus: RequestStatus.statuses.NULL,
  },
});

export const getPipelines: any = createAsyncThunk(
  "pipelines/getPipelines",
  async (context: UserContextType, thunkAPI) => {
    const response = await axios.get(
      getFullUrl("/api/pipeline", { useDedicatedEnvironment: true }),
      createRequestWithAuthHeaders(context),
    );

    return response?.data;
  },
);

export const getPipelinesForRecord: any = createAsyncThunk(
  "pipelines/getPipelinesForRecord",
  async (
    data: {
      context: UserContextType;
      table: "forecasts" | "contracts" | "grants";
      recordId: string | number;
    },
    thunkAPI,
  ) => {
    const { context, table, recordId } = data;
    const response = await axios.get(
      getFullUrl(
        `/api/${
          table === "forecasts"
            ? "businessforecast"
            : table === "contracts"
            ? "Contract"
            : "Grant"
        }/${recordId}/pipelines`,
        {
          useDedicatedEnvironment: true,
        },
      ),
      createRequestWithAuthHeaders(context),
    );

    return response?.data;
  },
);

export const addOrRemoveFromPipelines: any = createAsyncThunk(
  "pipelines/addToPipelines",
  async (
    data: {
      context: UserContextType;
      sourceTable: "forecast" | "contract" | "grant";
      forecastPipelineIdsToAdd: Array<{
        forecastId: string;
        pipelineId: number;
        secondaryId?: string;
      }>;
      forecastPipelineIdsToRemove: Array<{
        forecastId: string;
        pipelineId: number;
        secondaryId?: string;
      }>;
    },
    thunkAPI,
  ) => {
    const {
      context,
      sourceTable,
      forecastPipelineIdsToAdd,
      forecastPipelineIdsToRemove,
    } = data;
    return await Promise.all([
      ...(sourceTable !== "forecast"
        ? forecastPipelineIdsToAdd.map(async (forecastPipeline) => {
            await addToPipeline(
              context,
              sourceTable,
              forecastPipeline.pipelineId,
              forecastPipeline.forecastId,
              forecastPipeline.secondaryId ?? null,
            );
          })
        : [
            axios
              .post(
                getFullUrl(`/api/Pipeline/forecast`, {
                  useDedicatedEnvironment: true,
                }),
                { records: forecastPipelineIdsToAdd },
                createAuthenticatedRequest(context),
              )
              .then((result) => result?.data ?? null),
          ]),
      ...forecastPipelineIdsToRemove.map(async (forecastPipeline) => {
        await removeFromPipeline(
          context,
          sourceTable,
          forecastPipeline.pipelineId,
          forecastPipeline.forecastId,
          forecastPipeline.secondaryId ?? null,
        );
      }),
    ]);
    //
    // const response = await axios.post(
    //   getFullUrl(`/api/Pipeline/forecast`, {
    //     useDedicatedEnvironment: true,
    //   }),
    //   { records: forecastPipelineIdsToAdd },
    //   createAuthenticatedRequest(context),
    // );
    //
    // return response?.data ?? null;
  },
);

const slice = createSlice({
  name: "pipelines",
  initialState,
  reducers: {
    updatePipelines(
      state: PipelinesState,
      action: PayloadAction<{
        pipelines: Array<PipelineDto>;
        fetchStatus?: string;
      }>,
    ) {
      const fetchStatus =
        action.payload.fetchStatus ?? RequestStatus.statuses.DONE;
      state.items = action.payload.pipelines;
      state.rawItems = [];
      state.fetchStatus = fetchStatus;
    },
    setPipelinesForRecordFor(
      state: PipelinesState,
      action: PayloadAction<string | number | null>,
    ) {
      state.pipelinesForRecord.itemsFor = action.payload;
    },
    resetPipelinesForRecord(state: PipelinesState) {
      state.pipelinesForRecord = initialState.pipelinesForRecord;
    },
    resetAddOrRemoveFromPipelinesStatus(state: PipelinesState) {
      state.addOrRemoveFromPipelines = initialState.addOrRemoveFromPipelines;
    },
    resetPipelinesState(state: PipelinesState) {
      state.items = initialState.items;
      state.rawItems = initialState.rawItems;
      state.fetchStatus = initialState.fetchStatus;
    },
  },
  extraReducers: {
    [getPipelines.pending]: (state, action) => {
      state.fetchStatus = RequestStatus.statuses.FETCHING;
    },
    [getPipelines.fulfilled]: (state, action: PayloadAction<any>) => {
      state.rawItems = action.payload.filter(
        (pipeline) => pipeline.pipelineUserRole === "Admin",
      );
      state.fetchStatus = RequestStatus.statuses.HALF_DONE;
      // state.fetchStatus = RequestStatus.statuses.DONE;
    },
    [getPipelines.rejected]: (state, action) => {
      state.fetchStatus = RequestStatus.statuses.ERROR;
    },

    [getPipelinesForRecord.pending]: (state, action) => {
      state.pipelinesForRecord.fetchStatus = RequestStatus.statuses.FETCHING;
      // state.pipelinesForRecord.itemsFor
    },
    [getPipelinesForRecord.fulfilled]: (state, action: PayloadAction<any>) => {
      state.pipelinesForRecord.items = action.payload;
      state.pipelinesForRecord.fetchStatus = RequestStatus.statuses.DONE;
    },
    [getPipelinesForRecord.rejected]: (state, action) => {
      state.pipelinesForRecord.fetchStatus = RequestStatus.statuses.ERROR;
    },

    [addOrRemoveFromPipelines.pending]: (state, action) => {
      state.addOrRemoveFromPipelines.postFetchStatus =
        RequestStatus.statuses.FETCHING;
    },
    [addOrRemoveFromPipelines.fulfilled]: (
      state,
      action: PayloadAction<any>,
    ) => {
      state.addOrRemoveFromPipelines.postFetchStatus =
        RequestStatus.statuses.DONE;
    },
    [addOrRemoveFromPipelines.rejected]: (state, action) => {
      state.addOrRemoveFromPipelines.postFetchStatus =
        RequestStatus.statuses.ERROR;
    },
  },
});

const addToPipeline = async (
  context: UserContextType,
  sourceTable: "forecast" | "contract" | "grant",
  pipelineId: number,
  forecastId: string,
  secondaryId: string | null,
): Promise<void> => {
  const response = await axios.post(
    // getFullUrl(`/api/pipeline/${pipelineId}/${sourceTable}/${forecastId}`, {
    getFullUrl(
      `/api/pipeline/${pipelineId}/${sourceTable}${
        sourceTable === "contract"
          ? `?contractId=${forecastId}${
              secondaryId ? `&opportunityBaseId=${secondaryId}` : ""
            }`
          : `/${forecastId}`
      }`,
      {
        useDedicatedEnvironment: true,
      },
    ),
    null,
    createAuthenticatedRequest(context),
  );

  return response?.data ?? null;
};

const removeFromPipeline = async (
  context: UserContextType,
  sourceTable: "forecast" | "contract" | "grant",
  pipelineId: number,
  forecastId: string,
  secondaryId: string | null,
): Promise<void> => {
  const response = await axios.delete(
    // getFullUrl(`/api/pipeline/${pipelineId}/${sourceTable}/${forecastId}`, {
    getFullUrl(
      `/api/pipeline/${pipelineId}/${sourceTable}${
        sourceTable === "contract"
          ? `?contractId=${forecastId}${
              secondaryId ? `&opportunityBaseId=${secondaryId}` : ""
            }`
          : `/${forecastId}`
      }`,
      {
        useDedicatedEnvironment: true,
      },
    ),
    createAuthenticatedRequest(context),
  );

  return response?.data ?? null;
};

export const { reducer } = slice;

export const {
  updatePipelines,
  resetAddOrRemoveFromPipelinesStatus,
  resetPipelinesState,
  setPipelinesForRecordFor,
  resetPipelinesForRecord,
} = slice.actions;
export default slice;
