import { Box, Tab, Tabs } from "@mui/material";
import React, {
  Dispatch,
  SetStateAction,
  SyntheticEvent,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import ProjectsTable from "../../Projects/ProjectsTable";
import ManageOpportunitiesFilters from "./ManageOpportunitiesFilters";
import { Control, useForm, UseFormSetValue } from "react-hook-form";
import ProjectsList from "./ProjectsList";
import { RootState, useDispatch, useSelector } from "../../../store";
import {
  getAdminPrimesImplementersList,
  getAllCurrentPrimesForAdmin,
  getDropdownOptionsForAdminPrimes,
  getPrimesImplementerProjectsList,
  getPrimesImplementersList,
  resetAdminPrimesImplementersList,
} from "../../../slices/forecast";
import UserContext from "../../../services/UserContext";
import { ForecastRecord } from "../../../utils/types/Forecast";
import LikelyPrimesImplementersTable from "./LikelyPrimesImplementersTable";
import { RequestStatus } from "../../../utils/Helpers/fetchStatus";
import { isEqual } from "lodash";
import { rowsPerPageOptionsSmall } from "../../../utils/constants/gridsOptions";
import Input from "../../../components/Widgets/Inputs/Input";
import {
  AdminEditFormFields,
  SelectedImplementerWithProjects,
} from "../ForecastV2";

interface ManageOpportunityModalContentProps {
  openFor: ForecastRecord | null;
  adminEditControl: Control<AdminEditFormFields>;
  adminEditSetValue: UseFormSetValue<AdminEditFormFields>;
  selectedImplementers: SelectedImplementerWithProjects[];
  setSelectedImplementers: Dispatch<
    SetStateAction<SelectedImplementerWithProjects[]>
  >;
}

type TabValue = "primes" | "q&a" | "rfiRfp";

const tabs: readonly { value: TabValue; label: string }[] = [
  {
    value: "primes",
    label: "Likely primes",
  },
  {
    value: "q&a",
    label: "Quarterly Forecast Q&A and Notes",
  },
  {
    value: "rfiRfp",
    label: "Links to RFP/RFI",
  },
] as const;

function getInitialFilterValues(closingDate: Date) {
  return {
  source: "usa-spending",
  country: null,
  fundingAgency: null,
  awardFundingAgency: null,
  closingDate: closingDate,
  technicalSector: null,
  searchTerm: "",
  implementer: null,
};
}

export default function ManageOpportunityModalContent({
  openFor,
  adminEditControl,
  adminEditSetValue,
  selectedImplementers,
  setSelectedImplementers,
}: ManageOpportunityModalContentProps) {
  const initialFilterValues = getInitialFilterValues(new Date().addYears(-3));

  const { control, watch, setValue } = useForm({
    defaultValues: initialFilterValues,
  });

  const dispatch = useDispatch();
  const context = useContext(UserContext);

  const {
    fetchStatus,
    total,
    implementers,
    detailsFetchStatus,
    currentSavedImplementers,
  } = useSelector(
    (state: RootState) => state.forecast.primes.adminViewImplementers,
  );

  const [selectedTab, setSelectedTab] = useState<TabValue>(tabs[0].value);

  const fieldsValuesSnapshotRef = useRef(initialFilterValues);

  const [pagination, setPagination] = useState<{
    page: number;
    rowsPerPage: number;
  }>({
    page: 0,
    rowsPerPage: rowsPerPageOptionsSmall[1],
  });

  const fieldsValues = watch();

  function onTabChange(e: SyntheticEvent, tab: TabValue) {
    setSelectedTab(tab);
  }

  function queryData() {
    const { source, ...params } = fieldsValues;
    fieldsValuesSnapshotRef.current = fieldsValues;

    dispatch(
      getAdminPrimesImplementersList({
        context,
        source,
        params: {
          ...Object.entries(params).reduce((acc, [key, value]) => {
            if (value) {
              acc[key] = value;
            }
            return acc;
          }, {}),
          pageIndex: pagination.page,
          pageSize: pagination.rowsPerPage,
        },
      }),
    );
  }

  useEffect(() => {
    if (openFor) {
      queryData();
      dispatch(
        getAllCurrentPrimesForAdmin({
          context,
          forecastId: openFor.rowId,
        }),
      );
    }
  }, [pagination]);

  useEffect(() => {
    if (isEqual(fieldsValuesSnapshotRef.current, fieldsValues)) return;
    if (pagination.page === 0) {
      queryData();
    } else {
      setPagination({ ...pagination, page: 0 });
    }
  }, [fieldsValues]);

  useEffect(() => {
    dispatch(getDropdownOptionsForAdminPrimes(context));
    return () => {
      dispatch(resetAdminPrimesImplementersList());
      setSelectedImplementers([]);
    };
  }, []);

  useEffect(() => {
    if (RequestStatus.isDone(currentSavedImplementers.fetchStatus)) {
      setSelectedImplementers(
        currentSavedImplementers.items.map((implementer) => ({
          implementerUid: implementer.uid,
          implementerId: implementer.id,
          implementerName: `${implementer.country ?? ""} - ${
            implementer.implementerName
          }`,
          implementerRecordsCount: implementer.recordsCount,
          source: implementer.source,
          country: implementer.country,
          projects: implementer.records.map((record) => record.id),
          displayNames: implementer.records.map((record) => ({
            projectId: record.id,
            projectName: record.projectName ?? record.description,
          })),
          mode: "separate-included",
        })),
      );
    }
  }, [currentSavedImplementers.fetchStatus]);

  return (
    <Box
      sx={{
        width: "100%",
        flex: 1,
        display: "flex",
        flexDirection: "column",
        mt: -1,
      }}
    >
      <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
        <Tabs
          value={selectedTab}
          onChange={onTabChange}
          aria-label="basic tabs example"
        >
          {tabs.map((tab) => (
            <Tab value={tab.value} label={tab.label} />
          ))}
        </Tabs>
      </Box>
      {selectedTab === "primes" ? (
        <>
          <ManageOpportunitiesFilters
            control={control}
            setSearchValue={(value: string) => setValue("searchTerm", value)}
            loading={RequestStatus.isFetching(fetchStatus)}
            fieldsValues={fieldsValues}
          />
          <Box
            sx={{
              flex: 1,
              height: "100%",
              maxHeight: "45vh",
              display: "flex",
              gap: 1,
              overflow: "hidden",
            }}
          >
            <LikelyPrimesImplementersTable
              tableOpen={true}
              implementers={implementers}
              total={total}
              pagination={pagination}
              setPagination={setPagination}
              isProUser={true}
              openFor={openFor}
              fetchStatus={fetchStatus}
              detailsFetchStatus={detailsFetchStatus}
              isAdminTable={true}
              selectedImplementers={selectedImplementers}
              setSelectedImplementers={setSelectedImplementers}
            />
            <ProjectsList
              implementers={selectedImplementers}
              setSelectedImplementers={setSelectedImplementers}
              projectsLoading={RequestStatus.isFetching(
                currentSavedImplementers.fetchStatus,
              )}
            />
          </Box>
        </>
      ) : (
        <Box>
          <Box
            sx={{
              position: "relative",
              ...(selectedTab === "rfiRfp" && {
                opacity: 0,
                height: 0,
                zIndex: -1
              })
            }}
          >
          <Input
            type={"rich-text"}
            name={"quarterlyCallNotes"}
            control={adminEditControl}
            label={"Quarterly Forecast Q&A and Notes"}
          /></Box>
          <Box
            sx={{
                position: "relative",
              ...(selectedTab === "q&a" && {
                opacity: 0,
                height: 0,
                zIndex: -1
              })
            }}
          >
            <Input
              type={"rich-text"}
              name={"externalLinkHtml"}
              control={adminEditControl}
              label={"Links to RFP/RFI"}
              richTextUnformattedOnChange={(value: string) =>
                adminEditSetValue("externalLinkText", value)
              }
            />
          </Box>
        </Box>
      )}
    </Box>
  );
}
