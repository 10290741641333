import {
  Box,
  IconButton,
  Tab,
  Tabs,
  Tooltip,
  Typography,
  useTheme,
} from "@mui/material";
import Stack from "@mui/material/Stack";
import { ExpandLess } from "@mui/icons-material";
import React, {
  SyntheticEvent,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import UserContext, {
  isUserInRoles,
  UserRoleGroups,
} from "../../../services/UserContext";
import { ForecastRecord } from "../../../utils/types/Forecast";
import { RootState, useDispatch, useSelector } from "../../../store";
import { getPrimesImplementersList } from "../../../slices/forecast";
import LikelyPrimesImplementersTable from "./LikelyPrimesImplementersTable";

interface LikelyPrimesModalContentProps {
  openFor: ForecastRecord | null;
}

type TabValue = "descriptive" | "structured";

function getTabs(disableDescriptive: boolean): {
  value: TabValue;
  label: string;
  disabled: boolean;
  disabledTooltip: string;
}[] {
  return [
    {
      value: "structured",
      label: "Structured",
      disabled: false,
      disabledTooltip: "",
    },
    {
      value: "descriptive",
      label: "Descriptive",
      disabled: disableDescriptive,
      disabledTooltip: "No descriptive suggested primes available",
    },
  ];
}

export default function LikelyPrimesModalContent({
  openFor,
}: LikelyPrimesModalContentProps) {
  const dispatch = useDispatch();
  const context = useContext(UserContext);
  const theme = useTheme();

  const isProUser = isUserInRoles(context, UserRoleGroups.proOrHigher);

  const tabs = useMemo(() => getTabs(!openFor?.primeOrIncumbent), [openFor]);

  const { fetchStatus, implementers, total, detailsFetchStatus } = useSelector(
    (state: RootState) => state.forecast.primes,
  );

  const [selectedTab, setSelectedTab] = useState<TabValue>(tabs[0].value);

  const [showMultipleRelevantProjects, setShowMultipleRelevantProjects] =
    useState<boolean>(true);
  const [showOneRelevantProject, setShowOneRelevantProject] =
    useState<boolean>(true);

  const multipleRelevantProjects = useMemo(
    () => implementers.filter((implementer) => implementer.recordsCount > 1),
    [implementers],
  );
  const oneRelevantProjects = useMemo(
    () => implementers.filter((implementer) => implementer.recordsCount === 1),
    [implementers],
  );

  function onTabChange(e: SyntheticEvent, tab: TabValue) {
    setSelectedTab(tab);
  }

  useEffect(() => {
    if (openFor) {
      dispatch(
        getPrimesImplementersList({
          context,
          // source: "usa-spending",
          // source: "foreign-assistance",
          // forecastId: openFor.rowId,
          forecastId: openFor.rowId,
        }),
      );
    }
  }, [openFor]);

  return (
    <Box>
      <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
        <Tabs
          value={selectedTab}
          onChange={onTabChange}
          aria-label="basic tabs example"
        >
          {tabs.map((tab) => (
            <Tab
              value={tab.value}
              style={{
                pointerEvents: "auto",
                ...(tab.disabled && {
                  color: theme.palette.action.disabled,
                }),
              }}
              label={
                <Tooltip
                  title={tab.disabledTooltip}
                  key={tab.value}
                  disableFocusListener
                  disableTouchListener
                  disableInteractive
                  disableHoverListener={!tab.disabled}
                >
                  <span>{tab.label}</span>
                </Tooltip>
              }
              disabled={tab.disabled}
            />
          ))}
        </Tabs>
      </Box>
      {selectedTab === "structured" && (
        <Box>
          <Stack
            direction={"row"}
            sx={{
              p: 1,
              borderBottom: `solid 1px ${theme.palette.divider}`,
              // backgroundColor: theme.palette.primary.light,
            }}
            // justifyContent={"space-between"}
            alignItems={"center"}
          >
            <Typography variant={"h6"} fontWeight={500} color={"inherit"}>
              Multiple Relevant Projects
            </Typography>
            <IconButton
              onClick={() => setShowMultipleRelevantProjects((prev) => !prev)}
            >
              <ExpandLess
                sx={{
                  transition: "transform 250ms ease-in-out",
                  transform: `rotate(${
                    showMultipleRelevantProjects ? "0deg" : "180deg"
                  })`,
                }}
              />
            </IconButton>
          </Stack>
          {/*todo: separate implementers data into multiple and one relevant projects*/}
          <LikelyPrimesImplementersTable
            tableOpen={showMultipleRelevantProjects}
            implementers={multipleRelevantProjects}
            total={total}
            isProUser={isProUser}
            openFor={openFor}
            fetchStatus={fetchStatus}
            detailsFetchStatus={detailsFetchStatus}
            isAdminTable={false}
          />
          <Stack
            direction={"row"}
            sx={{
              p: 1,
              borderBottom: `solid 1px ${theme.palette.divider}`,
              // backgroundColor: theme.palette.primary.light,
            }}
            // justifyContent={"space-between"}
            alignItems={"center"}
          >
            <Typography variant={"h6"} fontWeight={500} color={"inherit"}>
              One Relevant Project
            </Typography>
            <IconButton
              onClick={() => setShowOneRelevantProject((prev) => !prev)}
            >
              <ExpandLess
                sx={{
                  transition: "transform 250ms ease-in-out",
                  transform: `rotate(${
                    showOneRelevantProject ? "0deg" : "180deg"
                  })`,
                }}
              />
            </IconButton>
          </Stack>
          {/*todo: separate implementers data into multiple and one relevant projects*/}
          <LikelyPrimesImplementersTable
            tableOpen={showOneRelevantProject}
            implementers={oneRelevantProjects}
            total={total}
            isProUser={isProUser}
            openFor={openFor}
            fetchStatus={fetchStatus}
            detailsFetchStatus={detailsFetchStatus}
            isAdminTable={false}
          />
        </Box>
      )}
      {selectedTab === "descriptive" && (
        <Typography
          variant={"body2"}
          sx={{
            mt: 2,
            display: "-webkit-box",
            overflow: "hidden",
            "& > *": {
              m: 0,
            },
          }}
          dangerouslySetInnerHTML={{
            __html:
              openFor?.primeOrIncumbent === null
                ? "No descriptive suggested primes available"
                : openFor?.primeOrIncumbent ?? "",
          }}
        />
      )}
    </Box>
  );
}
