import axios, { AxiosResponse } from "axios";
import {
  createAsyncThunk,
  createSlice,
  current,
  PayloadAction,
} from "@reduxjs/toolkit";
import {
  createAuthenticatedRequest,
  createRequestWithAuthHeaders,
  getFullUrl,
} from "../configs/axios-export.custom";
import { UserContextType } from "../services/UserContext";
import { ForecastRecord, ForecastSearch } from "../utils/types/Forecast";
import { RequestStatus } from "../utils/Helpers/fetchStatus";
import { buildQuery } from "../utils/Helpers/queryBuilder";
import { compact, difference } from "lodash";
import { ColumnsSettingsData } from "../components/MaterialTable/Table";
import {
  FORECAST_COLUMNS_SETTINGS_KEY,
  SelectedImplementerWithProjects,
} from "../screens/ForecastV2/ForecastV2";
import { htmlDecode } from "../utils/Helpers/htmlDecode";
import { shareOpportunityToUser } from "../utils/Helpers/asyncFunctions";

interface DropdownOption {
  value: string;
  label: string;
}

export interface PrimesImplementerRecord {
  id: number;
  projectName: string;
  view: string | null;
  description: string;
  startDate: string;
  endDate: string;
}

export interface PrimesImplementer {
  implementerName: string;
  id: number;
  /** Unique identifier for the implementer, with format {id}-{country}-{technical sector}, so each value can be extracted by splitting the string, if any of those properties is null it will also be a null word in the string */
  uid: string;
  source: "usa-spending" | "foreign-assistance";
  records: PrimesImplementerRecord[];
  recordsCount: number;
  country: string;
  legacyDuns?: string;
  uei?: string | null;
  technicalSector?: string;
}

interface ForecastState {
  items: ForecastRecord[];
  total: number;
  attributes: { [key: string]: any };
  lastUpdatedUtc: string | null;
  totalLiveRecords: number;
  fetchStatus: string;
  details: {
    fetchStatus: string;
    records: any[];
  };
  saveAdminData: {
    postFetchStatus: string;
  };
  shareOpportunities: {
    postFetchStatus: string;
  };
  dropdownOptions: {
    fetchStatus: string;
    countriesOffices: DropdownOption[];
    sectors: DropdownOption[];
    awardTypes: DropdownOption[];
    geographicalCodes: DropdownOption[];
  };
  tableSettings: {
    columnsOrder: ColumnsSettingsData[];
    fetchStatus: string;
    patchFetchStatus: string;
    deleteFetchStatus: string;
  };
  primes: {
    fetchStatus: string;
    detailsFetchStatus: string;
    implementers: PrimesImplementer[];
    total: number;
    adminViewImplementers: {
      fetchStatus: string;
      detailsFetchStatus: string;
      total: number;
      implementers: PrimesImplementer[];
      currentSavedImplementers: {
        items: PrimesImplementer[];
        fetchStatus: string;
      };
      attributes: Record<string, any>;
      lastUpdatedUtc: string | null;
      pageIndex: number;
      pageSize: number;
      savePrimes: {
        postFetchStatus: string;
        errors: Record<string, string>;
      };
      dropdownOptions: {
        fetchStatus: string;
        usaSpending: {
          countries: DropdownOption[];
          implementors: DropdownOption[];
        };
        foreignAssistance: {
          countries: DropdownOption[];
          technicalSectors: DropdownOption[];
          implementors: DropdownOption[];
          fundingAgencies: DropdownOption[];
          fundingAccounts: DropdownOption[];
        };
      };
    };
  };
}

const initialState: ForecastState = Object.freeze({
  items: [],
  total: 0,
  attributes: {},
  lastUpdatedUtc: null,
  totalLiveRecords: 0,
  fetchStatus: RequestStatus.statuses.NULL,
  details: {
    fetchStatus: RequestStatus.statuses.NULL,
    records: [],
  },
  saveAdminData: {
    postFetchStatus: RequestStatus.statuses.NULL,
  },
  shareOpportunities: {
    postFetchStatus: RequestStatus.statuses.NULL,
  },
  dropdownOptions: {
    fetchStatus: RequestStatus.statuses.NULL,
    countriesOffices: [],
    sectors: [],
    awardTypes: [],
    geographicalCodes: [],
  },
  tableSettings: {
    columnsOrder: [],
    fetchStatus: RequestStatus.statuses.NULL,
    patchFetchStatus: RequestStatus.statuses.NULL,
    deleteFetchStatus: RequestStatus.statuses.NULL,
  },
  primes: {
    fetchStatus: RequestStatus.statuses.NULL,
    detailsFetchStatus: RequestStatus.statuses.NULL,
    implementers: [],
    total: 0,
    adminViewImplementers: {
      fetchStatus: RequestStatus.statuses.NULL,
      detailsFetchStatus: RequestStatus.statuses.NULL,
      total: 0,
      implementers: [],
      currentSavedImplementers: {
        items: [],
        fetchStatus: RequestStatus.statuses.NULL,
      },
      attributes: {},
      lastUpdatedUtc: null,
      pageIndex: 0,
      pageSize: 20,
      savePrimes: {
        postFetchStatus: RequestStatus.statuses.NULL,
        errors: {},
      },
      dropdownOptions: {
        fetchStatus: RequestStatus.statuses.NULL,
        usaSpending: {
          countries: [],
          implementors: [],
        },
        foreignAssistance: {
          countries: [],
          technicalSectors: [],
          implementors: [],
          fundingAgencies: [],
          fundingAccounts: [],
        },
      },
    },
  },
});

export const getForecasts: any = createAsyncThunk(
  "forecast/getForecasts",
  async (
    data: { context: UserContextType; params: ForecastSearch },
    thunkAPI,
  ) => {
    const url = `/api/BusinessForecast${buildQuery(data.params)}`;
    const response = await axios.get(
      getFullUrl(url, {
        useDedicatedEnvironment: true,
      }),
      createRequestWithAuthHeaders(data.context),
    );

    return response?.data;
  },
);

export const getForecastDetails: any = createAsyncThunk(
  "forecast/getForecastDetails",
  async (
    data: {
      context: UserContextType;
      id: string;
    },
    thunkAPI,
  ) => {
    const { context, id } = data;
    const response = await axios.get(
      getFullUrl(`/api/BusinessForecast/${id}/details`, {
        useDedicatedEnvironment: true,
      }),
      createAuthenticatedRequest(context),
    );

    return response?.data ?? [];
  },
);

export const saveAdminData: any = createAsyncThunk(
  "forecast/saveAdminData",
  async (
    data: {
      context: UserContextType;
      loadedForecastId: number;
      subPrimes: string | null;
      quarterlyCallNotes: string | null;
      externalLinkHtml: string | null;
      externalLinkText: string | null;
    },
    thunkAPI,
  ) => {
    const { context, ...otherData } = data;
    const response = await axios.post(
      getFullUrl("/api/BusinessForecast/EnhancedForecast", {
        useDedicatedEnvironment: true,
      }),
      otherData,
      createRequestWithAuthHeaders(context),
    );
    return response?.data;
  },
);

export const shareOpportunities: any = createAsyncThunk(
  "forecast/shareOpportunities",
  async (
    data: {
      context: UserContextType;
      message: string;
      opportunityId: number | Array<number>;
      addresses: Array<string>;
    },
    thunkAPI,
  ) => {
    const { context, message, opportunityId, addresses } = data;
    return Array.isArray(opportunityId)
      ? await Promise.all(
          opportunityId.map(
            async (id) =>
              await Promise.all(
                addresses.map(async (address) => {
                  await shareOpportunityToUser({
                    context,
                    message,
                    opportunityId: id,
                    recordType: "F",
                    toAdderess: address,
                  });
                }),
              ),
          ),
        )
      : await Promise.all(
          addresses.map(async (address) => {
            await shareOpportunityToUser({
              context,
              message,
              opportunityId,
              recordType: "F",
              toAdderess: address,
            });
          }),
        );
  },
);

export const getForecastFiltersDropdownOptions: any = createAsyncThunk(
  "forecast/getForecastFiltersDropdownOptions",
  async (context: UserContextType, thunkAPI) => {
    const response = await Promise.all([
      axios.get(
        getFullUrl("/api/BusinessForecast/dropdownoptions/country", {
          useDedicatedEnvironment: true,
        }),
        createRequestWithAuthHeaders(context),
      ),
      axios.get(
        getFullUrl("/api/BusinessForecast/dropdownoptions/sector", {
          useDedicatedEnvironment: true,
        }),
        createRequestWithAuthHeaders(context),
      ),
      axios.get(
        getFullUrl("/api/BusinessForecast/dropdownoptions/awardtype", {
          useDedicatedEnvironment: true,
        }),
        createRequestWithAuthHeaders(context),
      ),
      axios.get(
        getFullUrl("/api/BusinessForecast/dropdownoptions/geographicalcode", {
          useDedicatedEnvironment: true,
        }),
        createRequestWithAuthHeaders(context),
      ),
    ]);
    return {
      countriesOffices: compact(response[0].data).map((option) => ({
        value: option, //htmlDecode(option),
        label: option, //htmlDecode(option),
      })),
      sectors: compact(response[1].data).map((option) => ({
        value: option, //htmlDecode(option),
        label: option, //htmlDecode(option),
      })),
      awardTypes: compact(response[2].data).map((option) => ({
        value: option, //htmlDecode(option),
        label: option, //htmlDecode(option),
      })),
      geographicalCodes: compact(response[3].data).map((option) => ({
        value: option, //htmlDecode(option),
        label: option, //htmlDecode(option),
      })),
    };
  },
);

export const getTableSettings: any = createAsyncThunk(
  "forecast/getTableSettings",
  async (data: { context: UserContextType; key: string }, thunkAPI) => {
    const { context, key } = data;
    const response = await axios.get(
      getFullUrl(`/api/UserSetting${buildQuery({ key })}`, {
        useDedicatedEnvironment: true,
      }),
      createRequestWithAuthHeaders(context),
    );

    return response?.data ?? [];
  },
);

export const patchTableSettings: any = createAsyncThunk(
  "forecast/patchTableSettings",
  async (
    data: {
      context: UserContextType;
      key: string;
      configuration: { [key: string]: Array<ColumnsSettingsData> };
    },
    thunkAPI,
  ) => {
    const { context, key, configuration } = data;
    const response = await axios.patch(
      getFullUrl(`/api/UserSetting/${key}`, {
        useDedicatedEnvironment: true,
      }),
      configuration,
      createRequestWithAuthHeaders(context),
    );

    return response?.data ?? [];
  },
);

export const deleteTableSettings: any = createAsyncThunk(
  "forecast/deleteTableSettings",
  async (data: { context: UserContextType; key: string }, thunkAPI) => {
    const { context, key } = data;
    return await axios.delete(
      getFullUrl(`/api/UserSetting/${key}`, {
        useDedicatedEnvironment: true,
      }),
      createRequestWithAuthHeaders(context),
    );
  },
);

export const getPrimesImplementersList: any = createAsyncThunk(
  "forecast/getPrimesImplementersList",
  async (
    data: {
      context: UserContextType;
      // source: "usa-spending" | "foreign-assistance";
      forecastId: number;
    },
    thunkAPI,
  ) => {
    const { context, forecastId } = data;
    // const response = await axios.get(
    //   getFullUrl(
    //     `/api/BusinessForecast/${forecastId}/${
    //       source === "usa-spending" ? "usaspendings" : "foreignAssistances"
    //     }/implementers`,
    //     {
    //       useDedicatedEnvironment: true,
    //     },
    //   ),
    //   createRequestWithAuthHeaders(context),
    // );

    const responses = await Promise.all([
      axios.get(
        getFullUrl(
          `/api/BusinessForecast/${forecastId}/foreignAssistances/implementers`,
          {
            useDedicatedEnvironment: true,
          },
        ),
        createRequestWithAuthHeaders(context),
      ),
      axios.get(
        getFullUrl(
          `/api/BusinessForecast/${forecastId}/usaspendings/implementers`,
          {
            useDedicatedEnvironment: true,
          },
        ),
        createRequestWithAuthHeaders(context),
      ),
    ]);

    return responses
      ? responses.map((response, _index) =>
          response.data.data.map((item) =>
            _index === 0
              ? {
                  id: item.implementerId,
                  implementerName: item.implementerName,
                  source: "foreign-assistance",
                  recordsCount: item.foreignAssistancesCount,
                  country: item.country,
                  technicalSector: item.technicalSector,
                }
              : {
                  id: item.recipientId,
                  implementerName: item.implementerName,
                  source: "usa-spending",
                  recordsCount: item.usaSpendingRecordsCount,
                  country: item.country,
                  technicalSector: item.technicalSector,
                },
          ),
        )
      : [];
  },
);

export const getPrimesImplementerProjectsList: any = createAsyncThunk(
  "forecast/getPrimesImplementerProjectsList",
  async (
    data: {
      context: UserContextType;
      source: "usa-spending" | "foreign-assistance";
      forecastId: string;
      implementerId: string;
      country: string;
      technicalSector?: string;
    },
    thunkAPI,
  ) => {
    const {
      context,
      source,
      forecastId,
      implementerId,
      country,
      technicalSector,
    } = data;
    const response = await axios.get(
      getFullUrl(
        `/api/BusinessForecast/${forecastId}/${
          source === "usa-spending" ? "usaspendings" : "foreignAssistances"
        }/implementers/${implementerId}?country=${country}${
          technicalSector ? `&technicalSector=${technicalSector}` : ""
        }`,
        {
          useDedicatedEnvironment: true,
        },
      ),
      createRequestWithAuthHeaders(context),
    );

    return {
      implementerId,
      country,
      records:
        source === "usa-spending"
          ? (response.data.data ?? []).map((item) => ({
              id: item.id,
              projectName: item.projectName,
              view: `/project-view/record?id=${item?.id}&fa=false`,
              description: item.projectDescription,
              startDate: item.startDate,
              endDate: item.currentEndDate,
            }))
          : (response.data.data ?? []).map((item) => ({
              id: item.id,
              projectName: item.activityName,
              view: item?.activityProjectInt
                ? `/project-view/record?projectNumber=${item?.activityProjectInt}&fa=true`
                : null,
              description: item.activityDescription,
              startDate: item.activityStartDate,
              endDate: item.activityEndDate,
            })),
    };
  },
);

export const getAdminPrimesImplementersList: any = createAsyncThunk(
  "forecast/getAdminPrimesImplementersList",
  async (
    data: {
      context: UserContextType;
      source: "usa-spending" | "foreign-assistance";
      params: Record<string, string | number>;
    },
    thunkAPI,
  ) => {
    const { context, source, params } = data;

    const response = await axios.get(
      getFullUrl(
        `/api/BusinessForecast/${
          source === "usa-spending" ? "usaspendings" : "foreignAssistances"
        }/implementers/admin${buildQuery({ ...params })}`,
        {
          useDedicatedEnvironment: true,
        },
      ),
      createRequestWithAuthHeaders(context),
    );

    return {
      source,
      data: response?.data ?? [],
    };
  },
);

export const getAdminPrimesImplementerProjectsList: any = createAsyncThunk(
  "forecast/getAdminPrimesImplementerProjectsList",
  async (
    data: {
      context: UserContextType;
      source: "usa-spending" | "foreign-assistance";
      forecastId: string;
      implementerId: string;
      uid: string;
      params: Record<string, string | number>;
    },
    thunkAPI,
  ) => {
    const { context, source, forecastId, implementerId, uid, params } = data;
    const response = await axios.get(
      getFullUrl(
        `/api/BusinessForecast/${forecastId}/${
          source === "usa-spending" ? "usaspendings" : "foreignAssistances"
        }/implementers/${implementerId}/admin${buildQuery({ ...params })}`,
        {
          useDedicatedEnvironment: true,
        },
      ),
      createRequestWithAuthHeaders(context),
    );

    return response?.data
      ? {
          uid,
          attributes: response?.data?.attributes ?? {},
          lastUpdatedUtc: response?.data?.lastUpdatedUtc ?? {},
          total: response?.data?.totalItems ?? {},
          pageIndex: response?.data?.pageIndex ?? {},
          pageSize: response?.data?.pageSize ?? {},
          records:
            source === "usa-spending"
              ? response.data.data.map((item) => ({
                  isSuggested: item.isSuggested,
                  id: item.usaSpending.id,
                  projectName: item.usaSpending.projectName,
                  view: `/project-view/record?id=${item.usaSpending?.id}&fa=false`,
                  description: item.usaSpending.projectDescription,
                  startDate: item.usaSpending.startDate,
                  endDate: item.usaSpending.currentEndDate,
                }))
              : response.data.data.map((item) => ({
                  isSuggested: item.isSuggested,
                  id: item.foreignAssistance.id,
                  projectName: item.foreignAssistance.activityName,
                  view: `/project-view/record?projectNumber=${item.foreignAssistance?.activityProjectInt}&fa=true`,
                  description: item.foreignAssistance.activityDescription,
                  startDate: item.foreignAssistance.activityStartDate,
                  endDate: item.foreignAssistance.activityEndDate,
                })),
        }
      : {};
  },
);

export const getDropdownOptionsForAdminPrimes: any = createAsyncThunk(
  "forecast/getDropdownOptionsForAdminPrimes",
  async (context: UserContextType, thunkAPI) => {
    const usResponse = await Promise.all([
      axios.get(
        getFullUrl(`/api/UsaSpending/dropdownoptions/country`, {
          useDedicatedEnvironment: true,
        }),
        createAuthenticatedRequest(context),
      ),
      axios.get(
        getFullUrl(`/api/UsaSpending/dropdownoptions/implementor`, {
          useDedicatedEnvironment: true,
        }),
        createAuthenticatedRequest(context),
      ),
    ]);

    const faResponse = await Promise.all([
      axios.get(
        getFullUrl(`/api/ForeignAssistance/dropdownoptions/country`, {
          useDedicatedEnvironment: true,
        }),
        createAuthenticatedRequest(context),
      ),
      axios.get(
        getFullUrl(`/api/ForeignAssistance/dropdownoptions/technicalSector`, {
          useDedicatedEnvironment: true,
        }),
        createAuthenticatedRequest(context),
      ),
      axios.get(
        getFullUrl(`/api/ForeignAssistance/dropdownoptions/implementor/primes`, {
          useDedicatedEnvironment: true,
        }),
        createAuthenticatedRequest(context),
      ),
      axios.get(
        getFullUrl(`/api/ForeignAssistance/dropdownoptions/fundingAgency`, {
          useDedicatedEnvironment: true,
        }),
        createAuthenticatedRequest(context),
      ),
      axios.get(
        getFullUrl(`/api/ForeignAssistance/dropdownoptions/fundingAccount`, {
          useDedicatedEnvironment: true,
        }),
        createAuthenticatedRequest(context),
      ),
    ]);

    return {
      usaSpending: {
        countries: compact(usResponse[0].data).map((option) => ({
          value: option,
          label: option,
        })),
        implementors: compact(usResponse[1].data).map((option) => ({
          value: option,
          label: option,
        })),
      },
      foreignAssistance: {
        countries: compact(faResponse[0].data).map((option) => ({
          value: option,
          label: option,
        })),
        technicalSectors: compact(faResponse[1].data).map((option) => ({
          value: option,
          label: option,
        })),
        implementors: compact(faResponse[2].data).map((option) => ({
          value: option,
          label: option,
        })),
        fundingAgencies: compact(faResponse[3].data).map((option) => ({
          value: option,
          label: option,
        })),
        fundingAccounts: compact(faResponse[4].data).map((option) => ({
          value: option,
          label: option,
        })),
      },
    };
  },
);

export const savePrimes: any = createAsyncThunk(
  "forecast/savePrimes",
  async (
    data: {
      context: UserContextType;
      forecastId: number;
      implementers: SelectedImplementerWithProjects[];
      currentState: PrimesImplementer[];
    },
    thunkAPI,
  ) => {
    const { context, forecastId, implementers, currentState } = data;

    const promises: any = [];

    const implementersUids = implementers.map((item) => item.implementerUid);

    const implementersToRemove = currentState.filter(
      (item) => !implementersUids.includes(item.uid),
    );

    implementersToRemove.forEach((implementer) => {
      const { source, id, country, technicalSector = null } = implementer;

      const url = `/api/BusinessForecast/${forecastId}/${
        source === "usa-spending" ? "usaspendings" : "foreignAssistances"
      }/${id}?country=${country}${
        technicalSector ? `&technicalSector=${technicalSector}` : ""
      }`;

      promises.push(
        axios.delete(
          getFullUrl(url, {
            useDedicatedEnvironment: true,
          }),
          {
            ...createRequestWithAuthHeaders(context),
            data: {
              selectedIds: [],
              excludedIds: [],
            },
          },
        ),
      );
    });

    implementers.forEach((implementer) => {
      const {
        source,
        implementerId,
        implementerUid,
        country,
        technicalSector = null,
        mode,
        projects,
      } = implementer;
      const url = `/api/BusinessForecast/${forecastId}/${
        source === "usa-spending" ? "usaspendings" : "foreignAssistances"
      }/${implementerId}?country=${country}${
        technicalSector ? `&technicalSector=${technicalSector}` : ""
      }`;

      const existingImplementer = currentState.find(
        (item) => item.uid === implementerUid,
      );

      if (mode === "separate-included") {
        const selectedIdsToRemove: number[] = difference(
          existingImplementer?.records.map((item) => item.id) ?? [],
          projects,
        );
        if (selectedIdsToRemove.length > 0) {
          promises.push(
            axios.delete(
              getFullUrl(url, {
                useDedicatedEnvironment: true,
              }),
              {
                ...createRequestWithAuthHeaders(context),
                data: {
                  selectedIds: selectedIdsToRemove,
                  excludedIds: [],
                },
              },
            ),
          );
        }
        const selectedIds = difference(
          projects,
          existingImplementer?.records.map((item) => item.id) ?? [],
        );
        if (selectedIds.length > 0) {
          promises.push(
            axios.post(
              getFullUrl(url, {
                useDedicatedEnvironment: true,
              }),
              {
                selectedIds,
                excludedIds: [],
              },
              createRequestWithAuthHeaders(context),
            ),
          );
        }
      } else if (mode === "separate-excluded") {
        promises.push(
          axios.post(
            getFullUrl(url, {
              useDedicatedEnvironment: true,
            }),
            {
              selectedIds: [],
              excludedIds: projects,
            },
            createRequestWithAuthHeaders(context),
          ),
        );
      } else {
        promises.push(
          axios.post(
            getFullUrl(url, {
              useDedicatedEnvironment: true,
            }),
            {
              selectedIds: [],
              excludedIds: [],
            },
            createRequestWithAuthHeaders(context),
          ),
        );
      }
    });

    const responses = Promise.all(promises);

    return {
      // source,
      // data: response?.data ?? [],
    };
  },
);

export const getAllCurrentPrimesForAdmin: any = createAsyncThunk(
  "forecast/getAllCurrentPrimesForAdmin",
  async (
    data: {
      context: UserContextType;
      forecastId: number;
    },
    thunkAPI,
  ) => {
    const { context, forecastId } = data;

    const implementersResponse = await Promise.all([
      axios.get(
        getFullUrl(
          `/api/BusinessForecast/${forecastId}/foreignAssistances/implementers`,
          {
            useDedicatedEnvironment: true,
          },
        ),
        createRequestWithAuthHeaders(context),
      ),
      axios.get(
        getFullUrl(
          `/api/BusinessForecast/${forecastId}/usaspendings/implementers`,
          {
            useDedicatedEnvironment: true,
          },
        ),
        createRequestWithAuthHeaders(context),
      ),
    ]);

    let implementers = implementersResponse
      ? implementersResponse
          .map((response, _index) =>
            response.data.data.map((item) =>
              _index === 0
                ? {
                    id: item.implementerId,
                    uid: `${item.implementerId}-${item.country ?? "null"}-${
                      item.technicalSector ?? "null"
                    }`,
                    implementerName: item.implementerName,
                    source: "foreign-assistance",
                    recordsCount: item.foreignAssistancesCount,
                    country: item.country,
                    technicalSector: item.technicalSector ?? null,
                  }
                : {
                    id: item.recipientId,
                    uid: `${item.recipientId}-${item.country ?? "null"}-${
                      item.technicalSector ?? "null"
                    }`,
                    implementerName: item.implementerName,
                    source: "usa-spending",
                    recordsCount: item.usaSpendingRecordsCount,
                    country: item.country,
                    technicalSector: item.technicalSector ?? null,
                  },
            ),
          )
          .flat()
      : [];

    const promises: any[] = [];

    implementers.forEach((implementer) => {
      const { id, source, country, technicalSector } = implementer;
      promises.push(
        axios.get(
          getFullUrl(
            `/api/BusinessForecast/${forecastId}/${
              source === "usa-spending" ? "usaspendings" : "foreignAssistances"
            }/implementers/${id}?country=${country}${
              technicalSector ? `&technicalSector=${technicalSector}` : ""
            }`,
            {
              useDedicatedEnvironment: true,
            },
          ),
          createRequestWithAuthHeaders(context),
        ),
      );
    });

    const projects = await Promise.all(promises);

    implementers = implementers.map((implementer, index) => {
      const response = projects[index];
      return {
        ...implementer,
        records:
          implementer.source === "usa-spending"
            ? (response.data.data ?? []).map((item) => ({
                id: item.id,
                projectName: item.projectName,
                view: `/project-view/record?id=${item?.id}&fa=false`,
                description: item.projectDescription,
                startDate: item.startDate,
                endDate: item.currentEndDate,
              }))
            : (response.data.data ?? []).map((item) => ({
                id: item.id,
                projectName: item.activityName,
                view: item?.activityProjectInt
                  ? `/project-view/record?projectNumber=${item?.activityProjectInt}&fa=true`
                  : null,
                description: item.activityDescription,
                startDate: item.activityStartDate,
                endDate: item.activityEndDate,
              })),
      };
    });

    return implementers;
  },
);

const slice = createSlice({
  name: "forecast",
  initialState,
  reducers: {
    resetGetForecastsStatus(state: ForecastState) {
      state.fetchStatus = initialState.fetchStatus;
    },
    resetGetForecasts(state: ForecastState) {
      state.fetchStatus = initialState.fetchStatus;
      state.items = initialState.items;
      state.total = initialState.total;
      state.totalLiveRecords = initialState.totalLiveRecords;
      state.attributes = initialState.attributes;
      state.lastUpdatedUtc = initialState.lastUpdatedUtc;
    },
    resetSaveAdminDataStatus(state: ForecastState) {
      state.saveAdminData = initialState.saveAdminData;
    },
    updateAfterAdminEdit(
      state: ForecastState,
      action: PayloadAction<{
        businessForecastId: string;
        subPrimes: string | null;
        quarterlyCallNotes: string | null;
        externalLinkHtml: string | null;
        externalLinkText: string | null;
      }>,
    ) {
      const {
        businessForecastId,
        subPrimes,
        quarterlyCallNotes,
        externalLinkHtml,
        externalLinkText,
      } = action.payload;
      const currentState = current(state);
      state.items = currentState.items.map((r: ForecastRecord) => {
        if (r.id === businessForecastId)
          return {
            ...r,
            primeOrIncumbent: subPrimes,
            quarterlyCallNotes: quarterlyCallNotes,
            externalLinkHtml: externalLinkHtml,
            externalLinkText: externalLinkText,
          };
        else return r;
      });
    },
    resetShareOpportunitiesStatus(state: ForecastState) {
      state.shareOpportunities = initialState.shareOpportunities;
    },
    resetTableSettings(state: ForecastState) {
      state.tableSettings = initialState.tableSettings;
    },
    resetPrimesImplementersList(state: ForecastState) {
      state.primes = initialState.primes;
    },
    resetAdminPrimesImplementersList(state: ForecastState) {
      state.primes.adminViewImplementers =
        initialState.primes.adminViewImplementers;
    },
    resetSavePrimes(state: ForecastState) {
      state.primes.adminViewImplementers.savePrimes =
        initialState.primes.adminViewImplementers.savePrimes;
    },
  },
  extraReducers: {
    [getForecasts.pending]: (state, action) => {
      state.fetchStatus = RequestStatus.statuses.FETCHING;
    },
    [getForecasts.fulfilled]: (state, action: PayloadAction<any>) => {
      const { data, totalItems, lastUpdatedUtc, attributes } = action.payload;
      // state.items = transformData(data);
      state.items = data.map((item) => ({
        ...item,
        changedColumns: item.changedColumns
          ? item.changedColumns
              .split(",")
              .map((item) => item?.trim()?.toLowerCase())
          : null,
      }));
      state.total = totalItems;
      state.attributes = attributes;
      state.lastUpdatedUtc = lastUpdatedUtc;
      if (!!attributes?.TotalLiveRecords)
        state.totalLiveRecords = attributes.TotalLiveRecords;
      state.fetchStatus = RequestStatus.statuses.DONE;
    },
    [getForecasts.rejected]: (state, action) => {
      state.fetchStatus = RequestStatus.statuses.ERROR;
    },

    [getForecastDetails.pending]: (state, action) => {
      state.details.fetchStatus = RequestStatus.statuses.FETCHING;
    },
    [getForecastDetails.fulfilled]: (state, action: PayloadAction<any>) => {
      state.details.records = action.payload?.sort(
        (r1, r2) =>
          new Date(r2.createdOn).getTime() - new Date(r1.createdOn).getTime(),
      );
      state.details.fetchStatus = RequestStatus.statuses.DONE;
    },
    [getForecastDetails.rejected]: (state, action) => {
      state.details.fetchStatus = RequestStatus.statuses.ERROR;
    },

    [saveAdminData.pending]: (state, action) => {
      state.saveAdminData.postFetchStatus = RequestStatus.statuses.FETCHING;
    },
    [saveAdminData.fulfilled]: (state, action: PayloadAction<any>) => {
      state.saveAdminData.postFetchStatus = RequestStatus.statuses.DONE;
    },
    [saveAdminData.rejected]: (state, action) => {
      state.saveAdminData.postFetchStatus = RequestStatus.statuses.ERROR;
    },

    [shareOpportunities.pending]: (state, action) => {
      state.shareOpportunities.postFetchStatus =
        RequestStatus.statuses.FETCHING;
    },
    [shareOpportunities.fulfilled]: (state, action: PayloadAction<any>) => {
      state.shareOpportunities.postFetchStatus = RequestStatus.statuses.DONE;
    },
    [shareOpportunities.rejected]: (state, action) => {
      state.shareOpportunities.postFetchStatus = RequestStatus.statuses.ERROR;
    },

    [getForecastFiltersDropdownOptions.pending]: (state, action) => {
      state.dropdownOptions.fetchStatus = RequestStatus.statuses.FETCHING;
    },
    [getForecastFiltersDropdownOptions.fulfilled]: (
      state,
      action: PayloadAction<any>,
    ) => {
      state.dropdownOptions = {
        ...action.payload,
        fetchStatus: RequestStatus.statuses.DONE,
      };
    },
    [getForecastFiltersDropdownOptions.rejected]: (state, action) => {
      state.dropdownOptions.fetchStatus = RequestStatus.statuses.ERROR;
    },

    [getTableSettings.pending]: (state, action) => {
      state.tableSettings.fetchStatus = RequestStatus.statuses.FETCHING;
    },
    [getTableSettings.fulfilled]: (state, action: PayloadAction<any>) => {
      state.tableSettings.columnsOrder =
        action.payload.setting[FORECAST_COLUMNS_SETTINGS_KEY];
      state.tableSettings.fetchStatus = RequestStatus.statuses.DONE;
    },
    [getTableSettings.rejected]: (state, action) => {
      state.tableSettings.fetchStatus = RequestStatus.statuses.ERROR;
    },

    [patchTableSettings.pending]: (state, action) => {
      state.tableSettings.patchFetchStatus = RequestStatus.statuses.FETCHING;
    },
    [patchTableSettings.fulfilled]: (state, action: PayloadAction<any>) => {
      state.tableSettings.patchFetchStatus = RequestStatus.statuses.DONE;
    },
    [patchTableSettings.rejected]: (state, action) => {
      state.tableSettings.patchFetchStatus = RequestStatus.statuses.ERROR;
    },

    [deleteTableSettings.pending]: (state, action) => {
      state.tableSettings.deleteFetchStatus = RequestStatus.statuses.FETCHING;
    },
    [deleteTableSettings.fulfilled]: (state, action: PayloadAction<any>) => {
      state.tableSettings.deleteFetchStatus = RequestStatus.statuses.DONE;
    },
    [deleteTableSettings.rejected]: (state, action) => {
      state.tableSettings.deleteFetchStatus = RequestStatus.statuses.ERROR;
    },

    [getPrimesImplementersList.pending]: (state, action) => {
      state.primes.fetchStatus = RequestStatus.statuses.FETCHING;
    },
    [getPrimesImplementersList.fulfilled]: (state, action) => {
      const [foreignAssistance, usaSpending] = action.payload;
      state.primes.implementers = [
        ...foreignAssistance.map((item) => ({
          ...item,
          uid: `${item.id}-${item.country ?? "null"}-${
            item.technicalSector ?? "null"
          }`,
          records: [],
        })),
        ...usaSpending.map((item) => ({
          ...item,
          uid: `${item.id}-${item.country ?? "null"}-${
            item.technicalSector ?? "null"
          }`,
          records: [],
        })),
      ];
      state.primes.fetchStatus = RequestStatus.statuses.DONE;
    },
    [getPrimesImplementersList.rejected]: (state, action) => {
      state.primes.fetchStatus = RequestStatus.statuses.ERROR;
    },

    [getPrimesImplementerProjectsList.pending]: (state, action) => {
      state.primes.detailsFetchStatus = RequestStatus.statuses.FETCHING;
    },
    [getPrimesImplementerProjectsList.fulfilled]: (state, action) => {
      const { implementerId, country, records } = action.payload;
      const implementer = state.primes.implementers.find(
        (item) => item.id === implementerId && item.country === country,
      );
      if (implementer) implementer.records = records;
      state.primes.detailsFetchStatus = RequestStatus.statuses.DONE;
    },
    [getPrimesImplementerProjectsList.rejected]: (state, action) => {
      state.primes.detailsFetchStatus = RequestStatus.statuses.ERROR;
    },

    [getAdminPrimesImplementersList.pending]: (state, action) => {
      state.primes.adminViewImplementers.fetchStatus =
        RequestStatus.statuses.FETCHING;
    },
    [getAdminPrimesImplementersList.fulfilled]: (state, action) => {
      const { source, data } = action.payload;

      state.primes.adminViewImplementers.implementers = data.data.map(
        (item) => ({
          source,
          implementerName: item.implementerName,
          id: item.implementerId ?? item.recipientId,
          uid: `${item.implementerId ?? item.recipientId}-${
            item.country ?? "null"
          }-${item.technicalSector ?? "null"}`,
          records: [],
          recordsCount:
            source === "usa-spending"
              ? item.usaSpendingRecordsCount ?? 0
              : item.foreignAssistancesCount ?? 0,
          country: item.country,
          legacyDuns: item.legacyDuns ?? null,
          uei: item.uei ?? null,
          technicalSector: item.technicalSector ?? null,
        }),
      );

      state.primes.adminViewImplementers.total = data.totalItems;
      state.primes.adminViewImplementers.attributes = data.attributes;
      state.primes.adminViewImplementers.fetchStatus =
        RequestStatus.statuses.DONE;
    },
    [getAdminPrimesImplementersList.rejected]: (state, action) => {
      state.primes.adminViewImplementers.fetchStatus =
        RequestStatus.statuses.ERROR;
    },

    [getAdminPrimesImplementerProjectsList.pending]: (state, action) => {
      state.primes.adminViewImplementers.detailsFetchStatus =
        RequestStatus.statuses.FETCHING;
    },
    [getAdminPrimesImplementerProjectsList.fulfilled]: (state, action) => {
      const { uid, records } = action.payload;
      const implementer = state.primes.adminViewImplementers.implementers.find(
        (item) => item.uid === uid,
      );
      if (implementer) implementer.records = records;
      state.primes.adminViewImplementers.detailsFetchStatus =
        RequestStatus.statuses.DONE;
    },
    [getAdminPrimesImplementerProjectsList.rejected]: (state, action) => {
      state.primes.adminViewImplementers.detailsFetchStatus =
        RequestStatus.statuses.ERROR;
    },

    [getDropdownOptionsForAdminPrimes.pending]: (state, action) => {
      state.primes.adminViewImplementers.dropdownOptions.fetchStatus =
        RequestStatus.statuses.FETCHING;
    },
    [getDropdownOptionsForAdminPrimes.fulfilled]: (state, action) => {
      state.primes.adminViewImplementers.dropdownOptions = {
        ...action.payload,
        fetchStatus: RequestStatus.statuses.DONE,
      };
    },
    [getDropdownOptionsForAdminPrimes.rejected]: (state, action) => {
      state.primes.adminViewImplementers.dropdownOptions.fetchStatus =
        RequestStatus.statuses.ERROR;
    },

    [savePrimes.pending]: (state, action) => {
      state.primes.adminViewImplementers.savePrimes.postFetchStatus =
        RequestStatus.statuses.FETCHING;
    },
    [savePrimes.fulfilled]: (state, action) => {
      state.primes.adminViewImplementers.savePrimes.postFetchStatus =
        RequestStatus.statuses.DONE;
    },
    [savePrimes.rejected]: (state, action) => {
      state.primes.adminViewImplementers.savePrimes.postFetchStatus =
        RequestStatus.statuses.ERROR;
      state.primes.adminViewImplementers.savePrimes.errors = action.payload;
    },

    [getAllCurrentPrimesForAdmin.pending]: (state, action) => {
      state.primes.adminViewImplementers.currentSavedImplementers.fetchStatus =
        RequestStatus.statuses.FETCHING;
    },
    [getAllCurrentPrimesForAdmin.fulfilled]: (state, action) => {
      state.primes.adminViewImplementers.currentSavedImplementers.fetchStatus =
        RequestStatus.statuses.DONE;
      state.primes.adminViewImplementers.currentSavedImplementers.items =
        action.payload;
    },
    [getAllCurrentPrimesForAdmin.rejected]: (state, action) => {
      state.primes.adminViewImplementers.currentSavedImplementers.fetchStatus =
        RequestStatus.statuses.ERROR;
    },
  },
});

export const { reducer } = slice;

export const {
  resetGetForecastsStatus,
  resetGetForecasts,
  updateAfterAdminEdit,
  resetSaveAdminDataStatus,
  resetShareOpportunitiesStatus,
  resetTableSettings,
  resetPrimesImplementersList,
  resetAdminPrimesImplementersList,
  resetSavePrimes,
} = slice.actions;

export default slice;
