import {
  alpha,
  Avatar,
  Box,
  Divider,
  Drawer,
  IconButton,
  List,
  ListItem,
  Tooltip,
  Typography,
  useTheme,
} from "@mui/material";
import React, { useContext, useEffect, useMemo, useState } from "react";
import {
  ECDFiltersProps,
  ECDRecord,
  getECDData,
  getECDDropdownOptions,
  resetResetECDRecord,
} from "../../slices/ecd";
import { RootState, useDispatch, useSelector } from "../../store";
import { useHistory } from "react-router-dom";
import UserContext, {
  doesUserHaveRole,
  isUserAdminOrSemiAdminWith,
  ServerOrgRoles,
} from "../../services/UserContext";
import {
  getSearchParamValue,
  setUrlParams,
} from "../../utils/Helpers/extractDataFromSearchParams";
import { useForm } from "react-hook-form";
import { rowsPerPageOptionsStandard } from "../../utils/constants/gridsOptions";
import { compact } from "lodash";
import CustomTable, {
  ColumnsSettingsData,
  DataColumnConfigProps,
  StickyColumnConfigProps,
} from "../../components/MaterialTable/Table";
import { getSortableColumnPropertyName } from "../Grants/grantsUtils";
import {
  getTableSettings,
  patchTableSettings,
} from "../../slices/user-settings";
import { RequestStatus } from "../../utils/Helpers/fetchStatus";
import { FiltersIcon } from "../../components/Icons/FiltersIcon";
import Button from "@mui/material/Button";
import ECDFilters from "./ECDFilters";
import { format } from "date-fns";
import { generateExcel } from "../../services/exporter";
import {
  getECDColumnsConfig,
  getECDStickyColumnsConfig,
} from "./ecdColumns.config";
import RiskManagementContent from "./RiskManagementContent";
import { getExcelDataECD, getExportableDataTableECD } from "./ecdUtils";
import { constants } from "../../utils/constants/general";
import TooltipWithScrollHide from "../../components/Widgets/TooltipWithScrollHide";
import LinkIcon from "@mui/icons-material/Link";
import SimpleModal from "../../components/Modals/SimpleModal";
import EditIcon from "@mui/icons-material/Edit";
import EditECDRowContent from "./EditECDRowContent";
import toast from "react-hot-toast";

type DatesType =
  | "ActivityDateFrom"
  | "ActivityDateTo"
  | "ExpirationDateFrom"
  | "ExpirationDateTo"
  | "EtdSignatureDateFrom"
  | "EtdSignatureDateTo"
  | "EtdSignatureFiscalYearFrom"
  | "EtdSignatureFiscalYearTo";

const FILTERS_FORM_ID = "ecd-filters-form";
export const ECD_COLUMNS_SETTINGS_KEY = "ecdColumnsSettingsKey";

const defaultFilters: Omit<
  ECDFiltersProps,
  "PageIndex" | "PageSize" | "SortField" | "SortOrder"
> = {
  Id: null,
  Countries: [],
  Bureaus: [],
  Sectors: [],
  Statuses: [],
  DocumentType: null,
  DocumentTitle: "",
  FileNumber: "",
  DocumentText: "",
  EtdSignatureDateFrom: null,
  EtdSignatureDateTo: null,
  EtdSignatureFiscalYearFrom: null,
  EtdSignatureFiscalYearTo: null,
  ActivityDateFrom: null,
  ActivityDateTo: null,
  ExpirationDateFrom: null,
  ExpirationDateTo: null,
};

export default function ECD() {
  const dispatch = useDispatch();
  const history = useHistory();
  const context = useContext(UserContext);

  const theme = useTheme();

  const canEdit =
    isUserAdminOrSemiAdminWith(
      context,
      ServerOrgRoles.EditPrimesAndQuarterlyCallInfo,
    ) || doesUserHaveRole(context, ServerOrgRoles.semiAdmin);

  function getFiltersFromParams(): Omit<
    ECDFiltersProps,
    "PageIndex" | "PageSize" | "SortField" | "SortOrder"
  > {
    const dateKeys: DatesType[] = [
      "ActivityDateFrom",
      "ActivityDateTo",
      "ExpirationDateFrom",
      "ExpirationDateTo",
      "EtdSignatureDateFrom",
      "EtdSignatureDateTo",
      "EtdSignatureFiscalYearFrom",
      "EtdSignatureFiscalYearTo",
    ];

    const dates = getDatesFromParams(dateKeys);

    return {
      Id: getSearchParamValue<number | null>(history, "Id", null),
      DocumentType: getDocumentTypeFromParams(),
      DocumentTitle: getSearchParamValue<string>(history, "DocumentTitle", ""),
      FileNumber: getSearchParamValue<string>(history, "FileNumber", ""),
      DocumentText: getSearchParamValue<string>(history, "DocumentText", ""),
      Countries: getArrayFromParams("Countries"),
      Bureaus: getArrayFromParams("Bureaus"),
      Sectors: getArrayFromParams("Sectors"),
      Statuses: getArrayFromParams("Statuses"),
      ...dates,
    };
  }

  function getDatesFromParams(
    dateKeys: DatesType[],
  ): Record<DatesType, Date | string | null> {
    let dates: Record<DatesType, Date | string | null> = {} as Record<
      DatesType,
      Date | string | null
    >;
    dateKeys.forEach((key) => {
      const _value = getSearchParamValue<string | null>(history, key, null);
      dates[key] = _value ? new Date(_value) : null;
    });
    return dates;
  }

  function getDocumentTypeFromParams() {
    const _documentType = getSearchParamValue<string | null>(
      history,
      "DocumentType",
      null,
    );
    return _documentType
      ? { value: _documentType, label: _documentType }
      : null;
  }

  function getArrayFromParams(param: string) {
    return getSearchParamValue<string[]>(history, param, [], {
      multiple: true,
    }).map((v) => ({ value: v, label: v }));
  }

  const { control, handleSubmit, reset, setValue, getValues } = useForm<
    Omit<ECDFiltersProps, "PageIndex" | "PageSize" | "SortField" | "SortOrder">
  >({
    defaultValues: getFiltersFromParams(),
  });

  const {
    tableSettings: { columnsOrder, fetchStatus: tableSettingsFetchStatus },
  } = useSelector((state: RootState) => state.userSettings);

  const { fetchStatus, total, items, lastUpdatedUtc, resetECDRecord } =
    useSelector((state: RootState) => state.ecd);

  const [filtersOpen, setFiltersOpen] = useState<boolean>(false);

  const [columns, setColumns] = useState<Array<any>>([]);
  const [documentsModalOpen, setDocumentsModalOpen] = useState<boolean>(false);
  const [documentsModalOpenFor, setDocumentsModalOpenFor] =
    useState<ECDRecord | null>(null);

  const [editModalOpen, setEditModalOpen] = useState<boolean>(false);
  const [editModalOpenFor, setEditModalOpenFor] = useState<ECDRecord | null>(
    null,
  );

  const stickyColumns = getECDStickyColumnsConfig();

  const [filtersSnapshot, setFiltersSnapshot] = useState<Omit<
    ECDFiltersProps,
    "PageIndex" | "PageSize" | "SortField" | "SortOrder"
  > | null>(null);

  const page = useMemo(
    () => +getSearchParamValue<string>(history, "PageIndex", "0"),
    [history.location],
  );
  const rowsPerPage = useMemo(
    () =>
      +getSearchParamValue<string>(
        history,
        "PageSize",
        rowsPerPageOptionsStandard[0].toString(),
      ),
    [history.location],
  );

  const sortField = useMemo(
    () => getSearchParamValue<string>(history, "SortField", "updatedOnUtc"),
    [history.location],
  );
  const sortOrder = useMemo(
    () => +getSearchParamValue<string>(history, "SortOrder", "0"),
    [history.location],
  );

  const filtersCount = useMemo(
    () =>
      Object.values(transformFiltersToDispatchParams(getValues())).filter(
        (value) => (Array.isArray(value) ? value.length > 0 : !!value),
      ).length,
    [history.location],
  );

  function handleOpenDocumentsModal(record: ECDRecord) {
    setDocumentsModalOpen(true);
    setDocumentsModalOpenFor(record);
  }

  function handleCloseDocumentsModal() {
    setDocumentsModalOpen(false);
    setTimeout(() => setDocumentsModalOpenFor(null), 150);
  }

  function handleOpenEditModal(record: ECDRecord) {
    setEditModalOpen(true);
    setEditModalOpenFor(record);
  }

  function handleCloseEditModal() {
    setEditModalOpen(false);
    setTimeout(() => setEditModalOpenFor(null), 150);
  }

  function onPageChange(
    e: React.MouseEvent<HTMLButtonElement>,
    page: number,
  ): void {
    setUrlParams(history, { PageIndex: page.toString() });
  }

  function onRowsPerPageChange(
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ): void {
    setUrlParams(history, { PageIndex: "0", PageSize: e.target.value });
  }

  function openFilters() {
    setFiltersOpen(true);
    setFiltersSnapshot(getValues());
  }

  function closeFilters(clearSnapshot: boolean = true) {
    setFiltersOpen(false);
    setFiltersSnapshot(null);
  }

  function cancelFilters() {
    closeFilters(false);
    if (filtersSnapshot) {
      setTimeout(() => {
        reset(filtersSnapshot);
        setFiltersSnapshot(null);
      }, 150);
    }
  }

  function clearFilters() {
    reset(defaultFilters);
  }

  function transformFiltersToDispatchParams(
    values: Record<string, any>,
    deleteFalsy: boolean = true,
  ): Record<string, string | string[]> {
    return Object.fromEntries(
      compact(
        Object.entries(values).map(([key, value]) => {
          if (
            deleteFalsy &&
            ((!value && value !== 0) ||
              (Array.isArray(value) && value.length === 0) ||
              (value as string).length === 0)
          )
            return null;
          let _v;
          if (Array.isArray(value))
            _v = value.map((v) => (v?.hasOwnProperty("value") ? v.value : v));
          else if (typeof (value as Date)?.getMonth === "function") {
            let _value = value as Date;
            if (_value.getTimezoneOffset() !== 0)
              _value.setHours(-_value.getTimezoneOffset() / 60);
            _v = _value
              .toISOString()
              .split(key.toLowerCase().includes("fiscalyear") ? "-" : "T")[0];
          } else _v = value;
          return [key, _v];
        }),
      ),
    );
  }

  function onFiltersSubmit(values: Record<string, any>) {
    setUrlParams(
      history,
      { ...transformFiltersToDispatchParams(values, false), PageIndex: "0" },
      {
        deleteFalsy: true,
      },
    );
    setTimeout(() => closeFilters(), 150);
  }

  function onSort(column: DataColumnConfigProps | StickyColumnConfigProps) {
    const propertyName = getSortableColumnPropertyName(column.propertyName);
    setUrlParams(
      history,
      sortField === propertyName
        ? sortOrder === 0
          ? { PageIndex: "0", SortField: propertyName, SortOrder: "1" }
          : sortOrder === 1
          ? { PageIndex: "0", SortField: propertyName, SortOrder: "-1" }
          : { PageIndex: "0", SortField: "", SortOrder: "0" }
        : { PageIndex: "0", SortField: propertyName, SortOrder: "1" },
    );
  }

  function handleSaveColumnsConfiguration(data: {
    [key: string]: Array<ColumnsSettingsData>;
  }): void {
    dispatch(
      patchTableSettings({
        context,
        key: ECD_COLUMNS_SETTINGS_KEY,
        configuration: data,
      }),
    );
  }

  useEffect(() => {
    dispatch(getECDDropdownOptions({ context }));
    dispatch(getTableSettings({ context, key: ECD_COLUMNS_SETTINGS_KEY }));

    return () => {
      setColumns([]);
    };
  }, []);

  useEffect(() => {
    dispatch(
      getECDData({
        context,
        params: {
          ...transformFiltersToDispatchParams(getValues()),
          PageIndex: page,
          PageSize: rowsPerPage,
          SortField: sortField,
          SortOrder: sortOrder,
        },
      }),
    );
  }, [history.location]);

  useEffect(() => {
    if (
      RequestStatus.isDone(tableSettingsFetchStatus) ||
      RequestStatus.isError(tableSettingsFetchStatus)
    ) {
      setColumns(
        getECDColumnsConfig(getSortableColumnPropertyName)
          .filter((column) => (column?.hiddenBasedOnRole ? canEdit : true))
          .map((column, idx) => ({
            ...column,
            order:
              columnsOrder.find(
                (_column) => _column.propertyName === column.propertyName,
              )?.order ?? idx,
            isVisible:
              columnsOrder.find(
                (_column) => _column.propertyName === column.propertyName,
              )?.isVisible ?? column.isVisible,
          }))
          .sort((a, b) => a.order - b.order),
      );
    }
  }, [tableSettingsFetchStatus, getSortableColumnPropertyName]);

  useEffect(() => {
    if (RequestStatus.isDone(resetECDRecord.fetchStatus)) {
      toast.success("Record reset successfully.");
      dispatch(resetResetECDRecord());
    } else if (RequestStatus.isError(resetECDRecord.fetchStatus)) {
      toast.error("Record reset failed");
      dispatch(resetResetECDRecord());
    }
  }, [resetECDRecord.fetchStatus]);

  return (
    <Box
      sx={{
        p: 2,
        textAlign: "start",
        width: "100%",
        display: "flex",
        flexDirection: "column",
      }}
    >
      <Drawer
        open={filtersOpen}
        onClose={cancelFilters}
        variant={"temporary"}
        anchor={"right"}
        sx={{
          zIndex: constants.zIndexes.drawer,
        }}
        PaperProps={{
          sx: {
            width: "480px",
            "& > div": {
              px: 2,
            },
          },
        }}
      >
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            gap: "16px",
            backgroundColor: theme.palette.secondary.light,
            borderBottom: `solid 3px ${theme.palette.secondary.main}`,
            py: 3,
          }}
        >
          <FiltersIcon />
          <Typography variant={"h5"}>Filters</Typography>
        </Box>
        <Box
          sx={{
            py: 2,
            overflowY: "scroll",
            flex: 1,
          }}
        >
          <form
            onSubmit={handleSubmit(onFiltersSubmit)}
            id={FILTERS_FORM_ID}
            style={{
              display: "flex",
              flexDirection: "column",
              gap: "20px",
            }}
          >
            <ECDFilters control={control} />
          </form>
        </Box>
        <Divider />
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "flex-end",
            gap: "16px",
            py: 2,
          }}
        >
          <Button
            variant={"secondaryContained"}
            color={"secondary"}
            onClick={cancelFilters}
          >
            Cancel
          </Button>
          <Button
            variant={"secondaryContained"}
            color={"secondary"}
            onClick={clearFilters}
          >
            Clear
          </Button>
          <Button
            variant={"contained"}
            color={"secondary"}
            // onClick={handleFilterData}
            type={"submit"}
            form={FILTERS_FORM_ID}
          >
            Save
          </Button>
        </Box>
      </Drawer>
      <SimpleModal
        open={documentsModalOpen}
        handleClose={handleCloseDocumentsModal}
        title={"Documents"}
        actions={
          <Box sx={{ pt: 2, display: "flex", justifyContent: "flex-end" }}>
            <Button
              variant={"secondaryContained"}
              onClick={handleCloseDocumentsModal}
              size={"small"}
            >
              Close
            </Button>
          </Box>
        }
        minWidth={"400px"}
      >
        <Typography variant={"h6"} color={"inherit"}>
          Parent Document
        </Typography>
        <Divider sx={{ mb: 1 }} />
        {documentsModalOpenFor?.parentDocument ? (
          <Typography
            variant={"subtitle2"}
            component={"a"}
            href={documentsModalOpenFor?.parentDocument?.url ?? ""}
            target={"_blank"}
            rel={"noreferrer noopener"}
            dangerouslySetInnerHTML={{
              __html: documentsModalOpenFor.parentDocument.name,
            }}
          />
        ) : (
          <Typography variant={"subtitle2"} color={"textSecondary"}>
            No parent document URL found.
          </Typography>
        )}
        <Typography variant={"h6"} color={"inherit"} sx={{ mt: 2 }}>
          Related Documents
        </Typography>
        <Divider />
        {documentsModalOpenFor &&
        documentsModalOpenFor.relatedDocuments?.length > 0 ? (
          <List
            sx={{
              listStyleType: "disc",
              pl: 3,
            }}
          >
            {documentsModalOpenFor.relatedDocuments.map((url, idx) => (
              <ListItem
                key={idx}
                sx={{
                  display: "list-item",
                  px: 0,
                  py: 0.5,
                }}
              >
                <Typography
                  variant={"subtitle2"}
                  component={"a"}
                  href={url.url}
                  target={"_blank"}
                  rel={"noreferrer noopener"}
                  dangerouslySetInnerHTML={{
                    __html: url?.name ?? url.url,
                  }}
                />
              </ListItem>
            ))}
          </List>
        ) : (
          <Typography
            variant={"subtitle2"}
            color={"textSecondary"}
            sx={{ mt: 1 }}
          >
            No related documents URLs found.
          </Typography>
        )}
      </SimpleModal>
      <SimpleModal
        open={editModalOpen}
        handleClose={handleCloseEditModal}
        title={"Admin Actions"}
        actions={
          <Box sx={{ pt: 2, display: "flex", justifyContent: "flex-end" }}>
            <Button
              variant={"secondaryContained"}
              onClick={handleCloseEditModal}
              size={"small"}
            >
              Close
            </Button>
          </Box>
        }
        minWidth={"400px"}
        loading={RequestStatus.isFetching(resetECDRecord.fetchStatus)}
      >
        <EditECDRowContent
          record={editModalOpenFor}
          resetFetchStatus={resetECDRecord.fetchStatus}
        />
      </SimpleModal>
      <Typography
        variant={"body2"}
        sx={{
          whiteSpace: "pre-wrap",
          "& > a": { textDecoration: "underline" },
        }}
      >
        The{" "}
        <a
          href={"https://ecd.usaid.gov/"}
          target={"_blank"}
          rel={"noreferrer noopener"}
        >
          Environmental Compliance Database
        </a>{" "}
        (ECD) is a searchable database that contains copies of the environmental
        impact assessments prepared and approved by USAID Missions and Regional
        and Global Bureaus. The ECD is updated as new documents are approved.
      </Typography>
      <Typography variant={"body2"} sx={{ mb: 3 }}>
        Source: ECD
      </Typography>
      {columns.length > 0 && (
        <CustomTable
          config={{
            data: items.map((item) => ({
              ...item,
              countries: item?.countries?.join(", "),
              sectorTypes: item?.sectorTypes?.join(", "),
              specificConditions: item?.specificConditions?.join(", "),
              thresholdDetermination: item?.thresholdDetermination?.join(", "),
              projectNumber: item?.projectNumber?.join(", "),
              awardNumbers: item?.awardNumbers?.join(", "),
              activityDateRange:
                !item?.projectDateActivityDateRangeStartParsed &&
                !item?.projectDateActivityDateRangeEndParsed
                  ? ""
                  : `${
                      item?.projectDateActivityDateRangeStartParsed
                        ? format(
                            new Date(
                              item.projectDateActivityDateRangeStartParsed,
                            ),
                            "dd MMMM yyyy",
                          )
                        : "N/A"
                    } - ${
                      item?.projectDateActivityDateRangeEndParsed
                        ? format(
                            new Date(
                              item.projectDateActivityDateRangeEndParsed,
                            ),
                            "dd MMMM yyyy",
                          )
                        : "N/A"
                    }`,
              adminActions: (
                <Tooltip title={"Edit"}>
                  <span>
                    <IconButton
                      sx={{
                        border: "solid 2px",
                        backgroundColor: alpha(theme.palette.success.main, 0.1),
                        position: "unset",
                      }}
                      color={"success"}
                      onClick={() => handleOpenEditModal(item)}
                    >
                      <EditIcon />
                    </IconButton>
                  </span>
                </Tooltip>
              ),
              climateRiskManagementAssessment: (
                <RiskManagementContent
                  value={item.climateRiskManagementAssessment}
                />
              ),
              documents: (
                <Box
                  sx={{
                    width: "100%",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <TooltipWithScrollHide
                    querySelectors={"[data-virtuoso-scroller]"}
                    title={"Documents"}
                  >
                    <span>
                      <IconButton
                        sx={{
                          border: "solid 2px",
                          backgroundColor: alpha(
                            theme.palette.primary.main,
                            0.1,
                          ),
                        }}
                        onClick={() => handleOpenDocumentsModal(item)}
                        color={"primary"}
                      >
                        <LinkIcon sx={{ width: "24px", height: "24px" }} />
                      </IconButton>
                    </span>
                  </TooltipWithScrollHide>
                </Box>
              ),
            })),
            columns,
            stickyColumns,
            header: [
              <Box
                sx={{
                  my: 1,
                }}
              >
                <Tooltip title={"Filters"}>
                  <span>
                    <Button
                      variant={"outlined"}
                      endIcon={
                        <FiltersIcon sx={{ width: "24px", height: "24px" }} />
                      }
                      size={"small"}
                      onClick={openFilters}
                    >
                      Filters
                      {filtersCount > 0 && (
                        <Avatar
                          sx={{
                            position: "absolute",
                            height: "16px",
                            width: "16px",
                            top: "2px",
                            right: "6px",
                            fontSize: "12px",
                            backgroundColor: theme.palette.secondary.main,
                            pointerEvents: "none",
                          }}
                        >
                          {filtersCount}
                        </Avatar>
                      )}
                    </Button>
                  </span>
                </Tooltip>
              </Box>,
            ],
            columnsConfiguration: {
              allowColumnsConfiguration: true,
              onSaveColumnsConfiguration: handleSaveColumnsConfiguration,
              columnsSettingsKey: ECD_COLUMNS_SETTINGS_KEY,
            },
            exportExcelFunc: async () =>
              generateExcel(
                "ECD",
                `Aidkonekt_ECD-${format(new Date(), "yyyy-MM-dd")}`,
                getExportableDataTableECD(
                  getExcelDataECD
                    ? await getExcelDataECD(
                        context,
                        {
                          ...transformFiltersToDispatchParams(getValues()),
                          SortOrder: sortOrder,
                          SortField: sortField,
                        },
                        total,
                      )
                    : items,
                ),
              ),
          }}
          loading={RequestStatus.isFetching(fetchStatus)}
          onPageChange={onPageChange}
          onRowsPerPageChange={onRowsPerPageChange}
          pagination={{
            page,
            rowsPerPage,
            total,
          }}
          currentSort={{
            sortField,
            sortOrder,
          }}
          onSortClick={onSort}
        />
      )}
    </Box>
  );
}
