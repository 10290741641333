export interface ShareUser {
  publicUserId: string;
  firstName: string;
  lastName: string;
  userRole: string;
  email: string;
}

export enum myListStatus {
  default = 0,
  bid,
  noBid,
  undecided,
}

export enum businessForecastRecordType {
  Default = "Default",
  NewRecord = "NewRecord",
  ModifiedRecord = "ModifiedRecord",
  FinalRecord = "FinalRecord",
}
export type PipelineForecasts = {
  id: number;
  name: string;
  businessForcast: [];
};
export interface ServerRecord {
  forecast: {
    id: string;
    rowId: number;
    country: string;
    sector: string;
    totalEstimatedCost: string;
    awardActionType: string;
    smallBusinessSetAside: string;
    anticipatedAwardDate: Date;
    anticipatedSolicitationReleaseDate: Date;
    createdOn: Date | null;
    status: businessForecastRecordType;
    changedColumns: string;
    awardTitle: string;
    awardDescription: string;

    aaPlanId: string;
    fiscalYearofAction: string;
    aaSpecialist: string;
    awardLength: string;
    eligibilityCriteria: string;
    geographicalCodes: string;
    categoryManagementContractVehicle: string;
    solicitationNumber: string;
    coCreation: string;
    location: string;
  };
  enhancements: {
    primeOrIncumbent: string;
    quarterlyCallNotes: string;
  };
  userEdits: {
    myListStatus: myListStatus;
    isMyList: boolean;
    email: string;
    notes: string;
  };
  pipelines: {
    numberIncludedPipelines: number;
  };
}

// export interface ForecastRecord {
//   id: string;
//   country: string;
//   createdOn?: Date | null;
//   isMyList: boolean;
//   awardTitle: string;
//   awardDescription: string;
//   sector: string;
//   primeOrIncumbent: string | null;
//   quarterlyCallNotes: string | null;
//   totalEstimatedCost: string;
//   awardActionType: string;
//   anticipatedSolicitationReleaseDate: Date;
//   anticipatedAwardDate: Date;
//   smallBusinessSetAside: string;
//   changedColumns: Array<string> | string | null;
//   aaPlanId: string;
//   fiscalYearofAction: string;
//   aaSpecialist: string;
//   awardLength: string;
//   eligibilityCriteria: string;
//   geographicalCodes: string;
//   categoryManagementContractVehicle: string;
//   solicitationNumber: string;
//   coCreation: string;
//   location: string;
//   rawRecord: ServerRecord;
// }

export interface ExtendedPrimesOrIncumbent {
  multipleRelevantProjects: any[];
  oneRelevantProject: any[];
}

export interface ForecastRecord {
  id: string;
  rowId: number;
  country: string;
  awardTitle: string;
  awardDescription: string;
  sector: string;
  totalEstimatedCost: string;
  awardActionType: string;
  smallBusinessSetAside: string;
  anticipatedAwardDate: string;
  anticipatedSolicitationReleaseDate: string;
  createdOn: string;
  status: string;
  changedColumns: string;
  fiscalYearofAction: string;
  aaSpecialist: string;
  awardLength: string;
  eligibilityCriteria: string;
  geographicalCodes: string;
  categoryManagementContractVehicle: string;
  solicitationNumber: string | null;
  coCreation: string;
  location: string;
  incumbent: null;
  aaPlanId: string;
  primeOrIncumbent: string | null;
  quarterlyCallNotes: string | null;
  numberIncludedPipelines: number;
  externalLinkHtml: string | null;
  externalLinkText: string | null;
}

export type ForecastSearch = {
  keyword: string;
  sortField: string;
  sortOrder: number;
  rowsPerPage: number;
  pageIndex: number;
  liveOnly: boolean;
  newOnly: boolean;
  OnlyUntrackedInPipeline: boolean;
};
export type PipelineDto = {
  id: number;
  name: string;
  businessForcast?: Array<ServerRecord>;
};

export interface ForecastPagination {
  page: number;
  rowsPerPage: number;
}

export interface ForecastSortOption {
  sortOrder: number;
  sortField: string;
}

export interface ForecastFiltersProps {
  keyword: string;
  liveOnly: boolean;
  newOnly: boolean;
  OnlyUntrackedInPipeline: boolean;
}

export interface ForecastExtendedFiltersProps {
  Countries: Array<string | { value: string; label: string }>;
  DateOfChangeBefore: Date | string | null;
  DateOfChangeAfter: Date | string | null;
  OpportunityTextKeyword: string | null;
  OpportunityDescriptionKeyword: string | null;
  SuggestedPrimesKeyword: string | null;
  QuarterlyForecastNotesKeyword: string | null;
  Sectors: Array<string | { value: string; label: string }>;
  // totalEstimatedCost: "",
  AwardTypes: Array<string | { value: string; label: string }>;
  GeographicalCodes: Array<string | { value: string; label: string }>;
  AnticipatedReleaseDateBefore: Date | string | null;
  AnticipatedReleaseDateAfter: Date | string | null;
  AnticipatedAwardDateBefore: Date | string | null;
  AnticipatedAwardDateAfter: Date | string | null;
}
