import React, { FC, useContext, useEffect, useMemo, useState } from "react";
import OpportunityDetails from "../../components/OpportunityDetails/OpportunityDetails";
import { RootState, useDispatch, useSelector } from "../../store";
import { useHistory } from "react-router-dom";
import { getContractDetails } from "../../slices/contracts";
import UserContext, { UserContextType } from "../../services/UserContext";
import { RequestStatus } from "../../utils/Helpers/fetchStatus";
import {
  getPipelinesForRecord,
  resetAddOrRemoveFromPipelinesStatus,
  resetPipelinesForRecord,
  setPipelinesForRecordFor,
} from "../../slices/pipelines";
import toast from "react-hot-toast";
import { getSearchParamValue } from "../../utils/Helpers/extractDataFromSearchParams";

const ContractsProfilePage: FC = (props): JSX.Element => {
  const dispatch = useDispatch();
  const history = useHistory();
  const context = useContext(UserContext);

  const {
    details: { files, matchingFiles, fetchStatus, records },
  } = useSelector((state: RootState) => state.contracts);
  const {
    fetchStatus: pipelinesForRecordFetchStatus,
    itemsFor: pipelinesForRecordFor,
  } = useSelector((state: RootState) => state.pipelines.pipelinesForRecord);
  const { postFetchStatus } = useSelector(
    (state: RootState) => state.pipelines.addOrRemoveFromPipelines,
  );
  const { postFetchStatus: shareOpportunitiesFetchStatus } = useSelector(
    (state: RootState) => state.contracts.shareOpportunities,
  );

  const [addToPipelinesOpen, setAddToPipelinesOpen] = useState<boolean>(false);

  const [isFirstLoading, setIsFirstLoading] = useState<boolean>(true);

  const id = useMemo(() => {
    const path = history.location.pathname.split("/");
    return path[path.length - 1];
  }, [history]);

  // const contractId = useMemo(() => {
  //   return getSearchParamValue(history, "id", null);
  // }, [history.location]);

  const [data, changelog] = useMemo(() => {
    const changelog = ([...records] as any)?.sort(
      (r1, r2) =>
        new Date(r2.createdOn).getTime() - new Date(r1.createdOn).getTime(),
    );
    const postedDateIn =
      changelog.length > 0
        ? changelog[changelog.length - 1].postedDateIn
        : null;

    const data = {
      ...changelog.shift(),
      postedDateIn,
    };
    return [data, changelog];
  }, [records]);

  const contractorAwardedAmount = useMemo(() => {
    const changelog = ([...records] as any)
      ?.sort(
        (r1, r2) =>
          new Date(r2.postedDateIn).getTime() -
          new Date(r1.postedDateIn).getTime(),
      )
      .filter((record) => record.contractorAwardedAmount !== null);
    const postedDateIn =
      changelog.length > 0
        ? changelog[changelog.length - 1].postedDateIn
        : null;

    const data = {
      ...changelog.shift(),
      postedDateIn,
    };

    return data.contractorAwardedAmount;
  }, [records]);

  useEffect(() => {
    if (!id) return;
    dispatch(
      getContractDetails({
        context,
        params: {
          id,
          fileKeyword: "",
          fileName: "",
          isCombinedDatasetRecord: true,
          checkIfNumeric: true,
        },
      }),
    );
  }, [id]);

  const handleOpenAddToPipelines = (): void => {
    if (!data?.contractOpportunityBase_Id) return;
    if (addToPipelinesOpen) {
      handleCloseAddToPipelines();
      setTimeout(() => {
        dispatch(setPipelinesForRecordFor(data.contractOpportunityBase_Id));
        dispatch(
          getPipelinesForRecord({
            context,
            table: "contracts",
            recordId: data.contractOpportunityBase_Id,
          }),
        );
      }, 100);
    } else {
      dispatch(setPipelinesForRecordFor(data.contractOpportunityBase_Id));
      dispatch(
        getPipelinesForRecord({
          context,
          table: "contracts",
          recordId: data.contractOpportunityBase_Id,
        }),
      );
    }
  };

  const handleCloseAddToPipelines = (): void => {
    setAddToPipelinesOpen(false);
    dispatch(setPipelinesForRecordFor(null));
    dispatch(resetPipelinesForRecord());
  };

  useEffect(() => {
    if (
      RequestStatus.isDone(pipelinesForRecordFetchStatus) &&
      pipelinesForRecordFor !== null &&
      !addToPipelinesOpen
    )
      setAddToPipelinesOpen(true);
    if (RequestStatus.isError(pipelinesForRecordFetchStatus)) {
      toast.error(
        "Pipeline data for this row couldn't be loaded. Try again later.",
      );
    }
  }, [pipelinesForRecordFetchStatus, pipelinesForRecordFor]);

  useEffect(() => {
    if (RequestStatus.isDone(postFetchStatus)) {
      handleCloseAddToPipelines();
      dispatch(resetAddOrRemoveFromPipelinesStatus());
      id &&
        dispatch(
          getContractDetails({
            context,
            params: {
              id,
              fileKeyword: "",
              fileName: "",
            },
          }),
        );
    }
  }, [postFetchStatus]);

  useEffect(() => {
    if (RequestStatus.isDone(fetchStatus) && isFirstLoading)
      setIsFirstLoading(false);
  }, [fetchStatus, isFirstLoading]);

  return (
    <>
      <OpportunityDetails
        source={"contracts"}
        data={{
          ...data,
          numberIncludedPipelines: data?.pipelineDetails?.length,
          contractorAwardedAmount:
            data?.contractorAwardedAmount ?? contractorAwardedAmount,
        }}
        loading={RequestStatus.isFetching(fetchStatus) && isFirstLoading}
        files={files}
        addToPipelinesOpen={addToPipelinesOpen}
        setAddToPipelinesOpen={setAddToPipelinesOpen}
        handleCloseAddToPipelines={handleCloseAddToPipelines}
        handleOpenAddToPipelines={handleOpenAddToPipelines}
        pipelinesFetchStatus={pipelinesForRecordFetchStatus}
        shareOpportunitiesFetchStatus={shareOpportunitiesFetchStatus}
        onShareSubmit={undefined}
        handleResetShareOpportunitiesStatus={undefined}
      />
    </>
  );
};

export default ContractsProfilePage;
