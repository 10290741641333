import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { DropdownOption } from "../components/Widgets/Inputs/Input";
import { UserContextType } from "../services/UserContext";
import axios from "axios";
import {
  createAuthenticatedRequest,
  getFullUrl,
} from "../configs/axios-export.custom";
import { buildQuery } from "../utils/Helpers/queryBuilder";
import { RequestStatus } from "../utils/Helpers/fetchStatus";
import { shareOpportunityToUser } from "../utils/Helpers/asyncFunctions";

export interface ContractsFileRecord {
  name: string;
  createdOn: string | null;
  type: string;
  description: string;
  uri?: string;
}

interface PointOfContact {
  phone: string;
  fullName: string;
  email: string;
  fax: string;
}

interface TableRecord {
  contractOpportunityBase_Id: number;
  id: string;
  notes: string;
  customColumn: string;
  isMyList: boolean;
  version: number | null;
  title: string;
  status: string;
  solicitationNumber: string;
  agencyName: string;
  type: string;
  setAside: string | null;
  placeOfPerformance: string | null;
  country: string;
  description: string;
  primeOrIncumbent: string;
  postedDateIn: string | null;
  solicitationDeadlineResponseIn: string | null;
  classificationCode: string;
  naicsCodes: string;
  lastUpdated: Date;
  modifiedDateIn: string | null;
  pointOfContactOne: PointOfContact | null;
  pointOfContactTwo: PointOfContact | null;
  contractorAwardedName: string | null;
  contractorAwardedDate: Date | null;
  contractorAwardedAmount: string | null;
  awardNumber: string | null;
  files: ContractsFileRecord[];
}

interface ExpandedRecord extends TableRecord {}

export interface ContractsFiltersType {
  filter?: string;
  country?: Array<DropdownOption>;
  opportunityType?: Array<DropdownOption>;
  setAsides?: Array<DropdownOption>;
  solicitationNumber?: string;
  sortField?: string;
  sortOrder?: number;
  onlyMyList?: boolean;
  showAwards?: boolean;
  includeUsaid?: boolean;
  includeOthers?: boolean;
  includeMcc?: boolean;
  includeStateDept?: boolean;
  includeCdc?: boolean;
  includeDfc?: boolean;
  fileName?: string;
  fileKeyword?: string;
  updatedBy?: Date | null;
  status?: string;
  onlyUntrackedInPipeline?: boolean;
}

export interface ContractsPagination {
  pageIndex: number;
  pageSize: number;
}

interface ContractsState {
  items: Array<any>;
  fetchStatus: string;
  total: number;
  lastUpdatedUtc: string | null;
  attributes: {
    [key: string]: any;
  };
  details: {
    fetchStatus: string;
    files: Array<ContractsFileRecord>;
    matchingFiles: Array<string>;
    records: Array<ExpandedRecord>;
  };
  shareOpportunities: {
    postFetchStatus: string;
  };
  dropdownOptions: {
    country: {
      items: Array<{ value: string; label: string }>;
      fetchStatus: string;
    };
    setAsides: {
      items: Array<{ value: string; label: string }>;
      fetchStatus: string;
    };
    statuses: {
      items: Array<{ value: string; label: string }>;
      fetchStatus: string;
    };
    opportunityTypes: {
      items: Array<{ value: string; label: string }>;
      fetchStatus: string;
    };
  };
}

const initialState: ContractsState = Object.freeze({
  items: [],
  fetchStatus: RequestStatus.statuses.NULL,
  total: 0,
  lastUpdatedUtc: null,
  attributes: {},
  details: {
    fetchStatus: RequestStatus.statuses.NULL,
    files: [],
    matchingFiles: [],
    records: [],
  },
  shareOpportunities: {
    postFetchStatus: RequestStatus.statuses.NULL,
  },
  dropdownOptions: {
    country: {
      items: [],
      fetchStatus: RequestStatus.statuses.NULL,
    },
    setAsides: {
      items: [],
      fetchStatus: RequestStatus.statuses.NULL,
    },
    statuses: {
      items: [
        { value: "Active", label: "Active" },
        { value: "Inactive", label: "Inactive" },
      ],
      fetchStatus: RequestStatus.statuses.NULL,
    },
    opportunityTypes: {
      items: [],
      fetchStatus: RequestStatus.statuses.NULL,
    },
  },
});

export const getContracts: any = createAsyncThunk(
  "contracts/getContracts",
  async (
    data: {
      context: UserContextType;
      params: ContractsFiltersType & ContractsPagination;
    },
    thunkAPI,
  ) => {
    const { context, params } = data;
    const response = await axios.get(
      getFullUrl(`/api/Contract${buildQuery({ ...params }, "|")}`, {
        useDedicatedEnvironment: true,
      }),
      createAuthenticatedRequest(context),
    );

    return response?.data ?? [];
  },
);

export const getCountryDropdownOptions: any = createAsyncThunk(
  "contracts/getCountryDropdownOptions",
  async (data: { context: UserContextType; filter: string }, thunkAPI) => {
    const { context, filter } = data;
    const response = await axios.get(
      getFullUrl(
        `/api/Contract/dropdownoptions/country${buildQuery({ filter })}`,
        {
          useDedicatedEnvironment: true,
        },
      ),
      createAuthenticatedRequest(context),
    );

    return response?.data ?? [];
  },
);

export const getSetAsideDropdownOptions: any = createAsyncThunk(
  "contracts/getSetAsideDropdownOptions",
  async (data: { context: UserContextType; filter: string }, thunkAPI) => {
    const { context, filter } = data;
    const response = await axios.get(
      getFullUrl(
        `/api/Contract/dropdownoptions/setAside${buildQuery({ filter })}`,
        {
          useDedicatedEnvironment: true,
        },
      ),
      createAuthenticatedRequest(context),
    );

    return response?.data ?? [];
  },
);

export const getOpportunityTypeDropdownOptions: any = createAsyncThunk(
  "contracts/getOpportunityTypeDropdownOptions",
  async (data: { context: UserContextType; filter: string }, thunkAPI) => {
    const { context, filter } = data;
    const response = await axios.get(
      getFullUrl(
        `/api/Contract/dropdownoptions/opportunityType${buildQuery({
          filter,
        })}`,
        {
          useDedicatedEnvironment: true,
        },
      ),
      createAuthenticatedRequest(context),
    );

    return response?.data ?? [];
  },
);

export const shareOpportunities: any = createAsyncThunk(
  "contracts/shareOpportunities",
  async (
    data: {
      context: UserContextType;
      message: string;
      opportunityId: number | Array<number>;
      addresses: Array<string>;
    },
    thunkAPI,
  ) => {
    const { context, message, opportunityId, addresses } = data;
    return Array.isArray(opportunityId)
      ? await Promise.all(
          opportunityId.map(
            async (id) =>
              await Promise.all(
                addresses.map(async (address) => {
                  await shareOpportunityToUser({
                    context,
                    message,
                    opportunityId: id,
                    recordType: "C",
                    toAdderess: address,
                  });
                }),
              ),
          ),
        )
      : await Promise.all(
          addresses.map(async (address) => {
            await shareOpportunityToUser({
              context,
              message,
              opportunityId,
              recordType: "C",
              toAdderess: address,
            });
          }),
        );
  },
);

export const getContractDetails: any = createAsyncThunk(
  "contracts/getContractDetails",
  async (
    data: {
      context: UserContextType;
      params: {
        id: string;
        fileKeyword?: string;
        fileName?: string;
        isCombinedDatasetRecord?: boolean;
        checkIfNumeric?: boolean;
      };
    },
    thunkAPI,
  ) => {
    const {
      context,
      params: {
        id,
        fileKeyword = null,
        fileName = null,
        isCombinedDatasetRecord = false,
        checkIfNumeric = false
      },
    } = data;
    let response;
    if(checkIfNumeric && isCombinedDatasetRecord && Number.isNaN(+id)) response = await axios.get(
      getFullUrl(
        `/api/Contract/opportunity/details/${id}${buildQuery({ fileKeyword, fileName }, null, true)}`,
        {
          useDedicatedEnvironment: true,
        },
      ),
      createAuthenticatedRequest(context),
    );
    else response = await axios.get(
      getFullUrl(
        `/api/Contract/details${
          isCombinedDatasetRecord ? "" : "/opportunity"
        }/${id}${buildQuery({ fileKeyword, fileName }, null, true)}`,
        {
          useDedicatedEnvironment: true,
        },
      ),
      createAuthenticatedRequest(context),
    );

    return response?.data ?? [];
  },
);

const slice = createSlice({
  name: "contracts",
  initialState,
  reducers: {
    resetShareOpportunitiesStatus(state: ContractsState) {
      state.shareOpportunities = initialState.shareOpportunities;
    },
    clearDetails(state: ContractsState) {
      state.details = initialState.details;
    },
  },
  extraReducers: {
    [getContracts.pending]: (
      state: ContractsState,
      action: PayloadAction<any>,
    ) => {
      state.fetchStatus = RequestStatus.statuses.FETCHING;
    },
    [getContracts.fulfilled]: (
      state: ContractsState,
      action: PayloadAction<any>,
    ) => {
      const { data, attributes, lastUpdatedUtc, totalItems } = action.payload;
      state.items = data;
      state.attributes = attributes;
      state.total = totalItems;
      state.lastUpdatedUtc = lastUpdatedUtc;
      state.fetchStatus = RequestStatus.statuses.DONE;
    },
    [getContracts.rejected]: (
      state: ContractsState,
      action: PayloadAction<any>,
    ) => {
      state.fetchStatus = RequestStatus.statuses.ERROR;
    },

    [shareOpportunities.pending]: (state, action) => {
      state.shareOpportunities.postFetchStatus =
        RequestStatus.statuses.FETCHING;
    },
    [shareOpportunities.fulfilled]: (state, action: PayloadAction<any>) => {
      state.shareOpportunities.postFetchStatus = RequestStatus.statuses.DONE;
    },
    [shareOpportunities.rejected]: (state, action) => {
      state.shareOpportunities.postFetchStatus = RequestStatus.statuses.ERROR;
    },

    [getCountryDropdownOptions.pending]: (
      state: ContractsState,
      action: PayloadAction<any>,
    ) => {
      state.dropdownOptions.country.fetchStatus =
        RequestStatus.statuses.FETCHING;
    },
    [getCountryDropdownOptions.fulfilled]: (
      state: ContractsState,
      action: PayloadAction<any>,
    ) => {
      state.dropdownOptions.country.items = action.payload.map((item) => ({
        value: item,
        label: item,
      }));
      state.dropdownOptions.country.fetchStatus = RequestStatus.statuses.DONE;
    },
    [getCountryDropdownOptions.rejected]: (
      state: ContractsState,
      action: PayloadAction<any>,
    ) => {
      state.dropdownOptions.country.fetchStatus = RequestStatus.statuses.ERROR;
    },

    [getSetAsideDropdownOptions.pending]: (
      state: ContractsState,
      action: PayloadAction<any>,
    ) => {
      state.dropdownOptions.setAsides.fetchStatus =
        RequestStatus.statuses.FETCHING;
    },
    [getSetAsideDropdownOptions.fulfilled]: (
      state: ContractsState,
      action: PayloadAction<any>,
    ) => {
      state.dropdownOptions.setAsides.items = action.payload.map((item) => ({
        value: item,
        label: item,
      }));
      state.dropdownOptions.setAsides.fetchStatus = RequestStatus.statuses.DONE;
    },
    [getSetAsideDropdownOptions.rejected]: (
      state: ContractsState,
      action: PayloadAction<any>,
    ) => {
      state.dropdownOptions.setAsides.fetchStatus =
        RequestStatus.statuses.ERROR;
    },

    [getOpportunityTypeDropdownOptions.pending]: (
      state: ContractsState,
      action: PayloadAction<any>,
    ) => {
      state.dropdownOptions.opportunityTypes.fetchStatus =
        RequestStatus.statuses.FETCHING;
    },
    [getOpportunityTypeDropdownOptions.fulfilled]: (
      state: ContractsState,
      action: PayloadAction<any>,
    ) => {
      state.dropdownOptions.opportunityTypes.items = action.payload.map(
        (item) => ({
          value: item,
          label: item,
        }),
      );
      state.dropdownOptions.opportunityTypes.fetchStatus =
        RequestStatus.statuses.DONE;
    },
    [getOpportunityTypeDropdownOptions.rejected]: (
      state: ContractsState,
      action: PayloadAction<any>,
    ) => {
      state.dropdownOptions.opportunityTypes.fetchStatus =
        RequestStatus.statuses.ERROR;
    },

    [getContractDetails.pending]: (
      state: ContractsState,
      action: PayloadAction<any>,
    ) => {
      state.details.fetchStatus = RequestStatus.statuses.FETCHING;
    },
    [getContractDetails.fulfilled]: (
      state: ContractsState,
      action: PayloadAction<any>,
    ) => {
      const files = action.payload
        .map((f) => f.files)
        .reduce((prev, curr) => [...prev, ...curr], [])
        .sort((a, b) =>
          new Date(a.createdOn || "9999-01-01") <
          new Date(b.createdOn || "9999-01-01")
            ? 1
            : -1,
        );
      const records = action.payload
        .map((f) => ({ ...f.record, pipelineDetails: f.pipelineDetails }))
        .sort((a, b) => {
          if (a.postedDateIn === null) return -1;
          if (b.postedDateIn === null) return 1;
          return a.postedDateIn > b.postedDateIn ? 1 : -1;
        });
      const matchingFiles = action.payload
        .map((f) => f.matchingFiles)
        .reduce((prev, curr) => [...prev, ...curr], []);

      state.details = {
        files,
        records,
        matchingFiles,
        fetchStatus: RequestStatus.statuses.DONE,
      };
    },
    [getContractDetails.rejected]: (
      state: ContractsState,
      action: PayloadAction<any>,
    ) => {
      state.details.fetchStatus = RequestStatus.statuses.ERROR;
    },
  },
});

export const { reducer } = slice;

export const { resetShareOpportunitiesStatus, clearDetails } = slice.actions;
export default slice;
